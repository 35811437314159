import * as React from "react";
import { Button, Dialog, DialogSurface } from "@fluentui/react-components";
import './dialog.scss';

interface IConfirmDialogProps {

}

interface IConfirmDialogState {
    isDialogHidden: boolean;
    dialogMessage: string;
    confirmButtonRef;
    cancelButtonRef;
    userResponded: boolean,
    userResponse: boolean,
    functionToExecute?: Function;

}

export default class ConfirmTeamDialog extends React.Component<IConfirmDialogProps, IConfirmDialogState>{

    constructor(props: any) {
        super(props);
        this.state = {
            isDialogHidden: true,
            dialogMessage: "",
            confirmButtonRef: React.createRef<HTMLButtonElement>(),
            cancelButtonRef: React.createRef<HTMLButtonElement>(),
            userResponded: false,
            userResponse: false,
        }
    }

    componentDidUpdate = () => {
        if (this.state.userResponded) {
            if (this.state.userResponse && this.state.functionToExecute != undefined) {
                this.state.functionToExecute();
            }
            this.setState({
                userResponded: false,
                functionToExecute: undefined,
            });
        }
    }

    showConfirmTeamDialog = (message: string, functionToExecute: Function) => {
        this.setState({
            dialogMessage: message,
            isDialogHidden: false,
            functionToExecute: functionToExecute,
        });
    }

    onConfirmClick = () => {
        this.setState({
            isDialogHidden: true,
            userResponded: true,
            userResponse: true,
        });
    }

    onCancelClick = () => {
        this.setState({
            isDialogHidden: true,
            userResponded: true,
            userResponse: false,
        });
    }

    render() {
        return (
            <Dialog
                open={!this.state.isDialogHidden}
            >
                <DialogSurface style={{display: 'flex', flexDirection: 'column' ,justifyContent: 'center', alignItems: 'end'}} className="admin-adding-dialog">
                    <div style={{
                        fontSize: "1.7rem",
                        fontWeight: "400",
                        marginBottom: 20
                    }}>{this.state.dialogMessage}</div>
                    <div style={{ display: "flex", justifyContent: 'end', gap: 5 }}>
                        <Button onClick={this.onCancelClick}>Nein</Button>
                        <Button appearance="primary" onClick={this.onConfirmClick}>Ja</Button>
                    </div>
                </DialogSurface>
            </Dialog>
        );
    }
}