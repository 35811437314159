import {
  Button,
} from "@fluentui/react-components";

export const WarningDialog = ({
  konfiguration = false,
  firstButtonFunction,
  secondButtonFunction,
  messageText = "",
}) => {
  return (
      <div className="Konfiguration-admin-delete-modal">
        {konfiguration ? (
          <>
            Achtung: <br />
            Sind Sie sicher, dass Sie diesen Gremium Administrator löschen
            möchten? <br />
            Er kann danach nur noch beschränkt auf die App zugreifen!
          </>
        ) : (
          <>{messageText}</>
        )}
        <div
        className="warning-dialog-buttons-container"
        >
          <Button
            className="deleteButton"
            onClick={() => {
              firstButtonFunction();
            }}
          >
            Löschen
          </Button>
          <Button
            onClick={() => {
              secondButtonFunction();
            }}
          >
            {konfiguration ? "Schließen" : "Abbrechnen"}
          </Button>
        </div>
      </div>
  );
};
