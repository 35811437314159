import React from "react";
import {
  MenuItem,
  MenuList,
  Button,
  Spinner,
  Text
} from "@fluentui/react-components";
import { TooltipHost } from "@fluentui/react";
import IToastNotification from "../../../models/toast-notification";
import { ActivityStatus } from "../../../models/activity-status";
import * as microsoftTeams from "@microsoft/teams-js";
import MeetingDetails from "./meeting-details";
import "./meeting-form.scss";
import { Guid } from "guid-typescript";
import { StatusCodes } from "http-status-codes";
import AgendaCreation from "./agenda-creation";
import Invitations from "./invitations";
import AttendanceList from "./attendance-list";
import MeetingLinks from "./meeting-links";
import MeetingFollowUp from "./meeting-follow-up";
import IPermission from "../../../models/IPermission";
import IGraphUser from "../../../models/graph-user";
import { getAllUsersAsync, getUserProfilesAsync } from "../../../api/users";
import { MeetingFormStep } from "./sitzung-form-tabs";
import { MeetingFormStepStatus } from "../../../enums/meeting-form-step-status.enum";
import { MeetingPhase } from "../../../enums/meeting-phase.enum";
import { finishMeetingPhase, getMeeting } from "../../../api/meeting-dto-api";
import { getAgenda } from "../../../api/agenda-dto-api";
import { getInvitation } from "../../../api/invitations-dto-api";
import { getAttendanceList, patchAttendanceList } from "../../../api/attendance-list-dto-api";
import { GenericApiFailMessage, LoaderMessage } from "../../../constants/textLabels";
import { getChecklist } from "../../../api/checklist-dto-api";
import IChecklistItem from "../../../models/nachbereiten/IchecklistItem";
import MeetingPhaseDisplay from "../../common/meeting-phase-display/meeting-phase-display";
import { keys } from "lodash";
import ConfirmDialog from "../../common/dialog/confirm-dialog";
import AlertDialog from "../../common/dialog/alert-dialog";
import { app } from "@microsoft/teams-js";
import { Themes } from "../../../constants/constants";
import { ErrorType } from "../../../enums/errorType";
import ApiAlertDialog from "../../common/dialog/api-alert-dialog";
import { CheckIcon, CloseIcon, MeetingLinksIcon } from "../../../utils/icons";
import Stepper from "../../stepper/stepper";
import { getAllMitglieders } from "../../../api/konfig-mitglieder-api";

interface MeetingFormProps {
  allSitzung: { id: string, name: string; start: Date }[];
  userPermissions: IPermission[];
  meetingId: string;
  closeDialog: Function;
  gremiumId: string;
}

interface MeetingFormState {
  notification: IToastNotification;
  meetingId: string;
  meetingStart?: Date;
  meetingEnd?: Date;
  meetingTitle: string;
  meetingCompletedAt: Date | null;
  allUsers: IGraphUser[];
  activeTab: MeetingFormStep;
  meetingFormStepStatuses: MeetingFormStepStatus[];
  saveDraft?: Function;
  dataHasChanged: boolean;
  // resetToInitialState?: Function;
  createMeeting?: Function;
  createAgenda?: Function;
  createProtocol?: Function;
  sendInvitations?: Function;
  createAttendanceList?: Function;
  lockCheclist?: Function;
  meetingLinksOpened: boolean;
  confirmDialogRef;
  isLoading: boolean;
  isPhaseFinishing: boolean;
  firstPhaseFinished: boolean;
  secondPhaseFinished: boolean;
  tabClicked: boolean;
  isFormValid: boolean;
  message: string;
  canChangeTab: boolean;
  sitzungCompleted: boolean;
  phaseTwoAlert: boolean;
  agendaDialogShow: boolean;
  stepClicked: {
    details: boolean;
    agenda: boolean;
    invitations: boolean;
    attendance: boolean;
    followup: boolean;
  }
  theme: string;
  allChecked: boolean;
  apiErrorDialogShow: boolean;
  apiAlertMessage: string;
  apiErrorType: ErrorType;
  phaseFinishFailed: boolean;
  anwesenhetslisteValid: boolean
}

const stepStatusNames = [
  'Sitzungsvorbereitung',
  'Kurz vor der Sitzung',
  'Sitzungsnachbereitung',
  'Sitzung abgeschlossen'
]

class MeetingForm extends React.Component<MeetingFormProps, MeetingFormState> {
  //TODO: Show confirm dialog on close (if data has changed), implement loading spinner
  constructor(props: any) {
    super(props);

    microsoftTeams.app.initialize();

    this.state = {
      anwesenhetslisteValid: false,
      phaseFinishFailed: false,
      meetingCompletedAt: null,
      apiErrorType: ErrorType.Information,
      apiAlertMessage: '',
      apiErrorDialogShow: false,
      allChecked: false,
      theme: Themes.default,
      stepClicked: {
        details: false,
        agenda: false,
        invitations: false,
        attendance: false,
        followup: false
      },
      agendaDialogShow: false,
      canChangeTab: false,
      message: '',
      isFormValid: false,
      tabClicked: false,
      notification: { id: 0, message: "", type: ActivityStatus.None },
      meetingId: this.props.meetingId,
      saveDraft: undefined,
      meetingStart: undefined,
      meetingEnd: undefined,
      meetingTitle: '',
      allUsers: [],
      activeTab: MeetingFormStep.MeetingDetails,
      meetingFormStepStatuses: [],
      dataHasChanged: false,
      meetingLinksOpened: false,
      confirmDialogRef: React.createRef(),
      isLoading: false,
      isPhaseFinishing: false,
      firstPhaseFinished: false,
      secondPhaseFinished: false,
      sitzungCompleted: false,
      phaseTwoAlert: false,
    };
  }

  componentDidMount = async () => {
    this.setState({ isLoading: true })
    app.getContext().then((context) => {
      this.setState({ theme: context.app.theme! });
    });

    app.registerOnThemeChangeHandler((theme: string) => {
      this.setState({ theme: theme! });
    });
    this.setState({ tabClicked: false });
    await this.loadData();
    this.setActiveTab();
    this.setState({ isLoading: false })
  }

  loadData = async () => {
    await this.loadAllUsers();
    await this.loadMeetingStepStatuses();
  }

  loadAllUsers = async () => {
    const response = await getAllMitglieders(window.alert);
    const allMitgliederIDS = response.data.map(u => u.graphUserId)
    const responseUsers = await getUserProfilesAsync(allMitgliederIDS,window.alert)
    if (response.status === StatusCodes.OK && responseUsers.data) {
      this.setState({ allUsers: responseUsers.data });
    }
  };

  loadMeetingStepStatuses = async () => {
    let meetingFormStepStatuses: MeetingFormStepStatus[] = [];

    meetingFormStepStatuses[MeetingFormStep.MeetingDetails] = MeetingFormStepStatus.Draft;
    meetingFormStepStatuses[MeetingFormStep.Agenda] = MeetingFormStepStatus.Draft;
    meetingFormStepStatuses[MeetingFormStep.Invitations] = MeetingFormStepStatus.Draft;
    meetingFormStepStatuses[MeetingFormStep.AttendanceList] = MeetingFormStepStatus.Draft;
    meetingFormStepStatuses[MeetingFormStep.Agenda] = MeetingFormStepStatus.Draft;
    meetingFormStepStatuses[MeetingFormStep.MeetingFollowUp] = MeetingFormStepStatus.Draft;

    if (this.props.meetingId && this.state.meetingId != Guid.EMPTY) {
      const meetingDetailsResponse = await getMeeting(this.state.meetingId);
      const agendaResponse = await getAgenda(this.state.meetingId);
      const invitationResponse = await getInvitation(this.state.meetingId);
      const attendanceListResponse = await getAttendanceList(this.state.meetingId);
      const checklistResponse = await getChecklist(this.state.meetingId);
      if (meetingDetailsResponse.status == StatusCodes.OK &&
        agendaResponse.status == StatusCodes.OK &&
        invitationResponse.status == StatusCodes.OK &&
        attendanceListResponse.status == StatusCodes.OK &&
        checklistResponse.status == StatusCodes.OK) {

        if (meetingDetailsResponse.data.completedAt) {
          this.setState({ meetingCompletedAt: meetingDetailsResponse.data.completedAt })
        }
        this.setState({
          stepClicked: {
            details: true,
            agenda: agendaResponse.data[0] && agendaResponse.data[0].tagesordnungstatus === MeetingFormStepStatus.Processed,
            invitations: invitationResponse.data[0] && invitationResponse.data[0].einladungsstatus === MeetingFormStepStatus.Processed,
            attendance: attendanceListResponse.data[0] && attendanceListResponse.data[0].anwesenheitslistenstatus === MeetingFormStepStatus.Processed,
            followup: false
          }
        })
        const meetingDetailsStatus: MeetingFormStepStatus = meetingDetailsResponse.data.teamsChannelId == null ? MeetingFormStepStatus.ReadyForProcessing : MeetingFormStepStatus.Processed;
        const agendaStatus: MeetingFormStepStatus = agendaResponse.data[0] ? agendaResponse.data[0].tagesordnungstatus : MeetingFormStepStatus.Draft;
        const invitationStatus: MeetingFormStepStatus = invitationResponse.data[0] ? invitationResponse.data[0].einladungsstatus : MeetingFormStepStatus.Draft;
        const attendanceListStatus: MeetingFormStepStatus = attendanceListResponse.data[0] ? attendanceListResponse.data[0].anwesenheitslistenstatus : MeetingFormStepStatus.Draft;

        const meetingCompletedAt = meetingDetailsResponse.data.completedAt;

        let checklistStatus;
        if (meetingCompletedAt) {
          this.setState({ meetingCompletedAt })
          checklistStatus = MeetingFormStepStatus.Processed;
        } else if (checklistResponse.data.length > 1 && checklistResponse.data.find((cb: IChecklistItem) => cb.value === false) === undefined) {
          checklistStatus = MeetingFormStepStatus.ReadyForProcessing;
        } else {
          checklistStatus = MeetingFormStepStatus.Draft;
        }

        meetingFormStepStatuses[MeetingFormStep.MeetingDetails] = meetingDetailsStatus;
        meetingFormStepStatuses[MeetingFormStep.Agenda] = agendaStatus;
        meetingFormStepStatuses[MeetingFormStep.Invitations] = invitationStatus;
        meetingFormStepStatuses[MeetingFormStep.AttendanceList] = attendanceListStatus;
        meetingFormStepStatuses[MeetingFormStep.MeetingFollowUp] = checklistStatus;
      }
    }
    await this.setState({
      meetingFormStepStatuses: meetingFormStepStatuses
    });
  }

  setActiveTab = () => {
    const phase = this.getMeetingPhase();
    let tab = MeetingFormStep.MeetingDetails;
    const formStepKeys = keys(MeetingFormStep);
    switch (phase) {
      case MeetingPhase.Default: {
        tab = MeetingFormStep.MeetingDetails; break;
      } case MeetingPhase.MeetingPreparation: {
        tab = MeetingFormStep.Invitations;
        for (let i = MeetingFormStep.MeetingDetails; i <= MeetingFormStep.Invitations; i++) {
          if (this.state.meetingFormStepStatuses[i] === MeetingFormStepStatus.Draft && tab == MeetingFormStep.Invitations && i > MeetingFormStep.MeetingDetails) {
            tab = i - 1;
          }
        }
        break;
      } case MeetingPhase.JustBeforeMeeting: {
        tab = MeetingFormStep.AttendanceList;
        for (let i = MeetingFormStep.MeetingDetails; i <= MeetingFormStep.AttendanceList; i++) {
          if (this.state.meetingFormStepStatuses[i] === MeetingFormStepStatus.NeedsReprocessing && tab == MeetingFormStep.AttendanceList) {
            tab = i;
          }
        }
        break;
      } case MeetingPhase.MeetingFollowUp: {
        tab = MeetingFormStep.MeetingFollowUp; break;
      } case MeetingPhase.MeetingLocked: {
        tab = MeetingFormStep.MeetingFollowUp; break;
      }
    }
    this.setState({
      activeTab: tab
    }, this.updateNavMenu);
  }

  setSaveAndResetFunctions = (saveDraft?: Function) => {
    this.setState({
      saveDraft: saveDraft,
      // resetToInitialState: resetToInitialState 
    });
  }

  setMeetingId = (id: string) => {
    this.setState({ meetingId: id });
  };

  setDataHasChanged = async (dataHasChanged: boolean) => {
    if (dataHasChanged && this.state.activeTab === MeetingFormStep.Agenda && this.getActivePhase() === MeetingPhase.JustBeforeMeeting) {
      this.setState({ agendaDialogShow: true })
    };
    this.loadMeetingStepStatuses();
    this.setState({
      dataHasChanged: dataHasChanged,
    });
  };

  setOrderHasChanged = async (dataHasChanged: boolean) => {
    if (dataHasChanged && this.state.meetingFormStepStatuses[this.state.activeTab] == MeetingFormStepStatus.Processed) {
      this.loadMeetingStepStatuses();
    }
    this.setState({
      dataHasChanged: dataHasChanged,
    });
  };

  setMeetingTimes = (meetingStart: Date, meetingEnd: Date) => {
    this.setState({ meetingStart: meetingStart, meetingEnd: meetingEnd });
  }

  setMeetingTitle = (title: string) => {
    if (this.state.meetingTitle != title) {
      this.setState({ meetingTitle: title });
    }
  }

  setIsLoading = (isLoading: boolean) => {
    this.setState({ isLoading: isLoading });
  }

  setPhaseFinishing = (isPhaseFinishing: boolean) => {
    this.setState({ isPhaseFinishing: isPhaseFinishing });
  }

  meetingHasStarted = (): boolean => {
    if (this.state.meetingStart === undefined) { return false; }
    return new Date().getTime() >= new Date(this.state.meetingStart).getTime();
  }

  meetingHasEnded = (): boolean => {
    if (this.state.meetingEnd === undefined) { return false; }
    return new Date().getTime() >= new Date(this.state.meetingEnd).getTime();
  }

  meetingHasCompletedAt = (): boolean => {
    if (this.state.meetingCompletedAt) { return true; }
    return false
  }

  onCloseClick = async (e) => {
    this.setState({ isLoading: true })
    await finishMeetingPhase(this.state.meetingId, MeetingPhase.MeetingFollowUp);
    this.loadMeetingStepStatuses();
    setTimeout(() => {
      this.setState({ sitzungCompleted: true, isLoading: false })
    }, 500);
  };

  onCloseDialogClick = async (e) => {
    this.props.closeDialog()
  };

  setAnwesenhetslisteValid = (valid) => {
    this.setState({ anwesenhetslisteValid: valid })
  }

  executeSaveDraft = async () => {
    if (this.state.saveDraft) {
      const result = await this.state.saveDraft(undefined, undefined, true);
      return result;
    } else {
      return true;
    }
  };

  // executeResetToInitialState = async () => {
  //   if (this.state.resetToInitialState) {
  //     const result = await this.state.resetToInitialState(true);
  //     this.setState({ dataHasChanged: false })
  //     return result;
  //   } else {
  //     this.setState({ dataHasChanged: false })
  //     return true;
  //   }
  // };

  nextTab = async () => {
    const result = await this.executeSaveDraft();
    if (result === true) {
      let activeTab: number
      if (this.state.dataHasChanged
        && (this.state.meetingFormStepStatuses[this.state.activeTab] == MeetingFormStepStatus.NeedsReprocessing
        ) && this.state.activeTab !== MeetingFormStep.AttendanceList && this.getActivePhase() < MeetingPhase.MeetingFollowUp) {
        window.alert(this.getWarningMessage());
      }

      activeTab = this.state.activeTab + 1;
      // if (this.state.activeTab == MeetingFormStep.AttendanceList && this.getActivePhase() < MeetingPhase.MeetingFollowUp) {
      //   activeTab = this.state.activeTab + 2
      // } Commented out because we now enabled the meeting follow up to be reachable even when the meeting already started
      if (this.isTabAvailable(activeTab)) {
        this.setState({ activeTab: activeTab, meetingLinksOpened: false }, this.updateNavMenu);
      }
    }

  };

  previousTab = async () => {
    const result = await this.executeSaveDraft();
    if (result === true) {
      if (this.state.dataHasChanged
        && (this.state.meetingFormStepStatuses[this.state.activeTab] == MeetingFormStepStatus.NeedsReprocessing
        )) {
        window.alert(this.getWarningMessage());
      }
      const activeTab = this.state.activeTab - 1;
      if (this.isTabAvailable(activeTab)) {
        this.setState({ activeTab: activeTab, meetingLinksOpened: false }, this.updateNavMenu);
      } else if (this.state.activeTab === 5) {
        this.setState({ activeTab: activeTab - 1, meetingLinksOpened: false }, this.updateNavMenu);
      }
    }
  };

  changeTab = async (index) => {
    if (this.state.activeTab == MeetingFormStep.MeetingDetails) {
      if (!this.state.isFormValid && !this.validateBeforeChangingTab()) {
        return
      }
    }
    // const result = await this.executeSaveDraft();
    // if (result === true) {
    if (this.state.dataHasChanged
      && (this.state.meetingFormStepStatuses[this.state.activeTab] == MeetingFormStepStatus.NeedsReprocessing
      ) && this.state.activeTab !== MeetingFormStep.AttendanceList && this.getActivePhase() < MeetingPhase.MeetingFollowUp) {
      window.alert(this.getWarningMessage());
    }
    const activeTab = index;
    if (this.isTabAvailable(activeTab)) {
      if (activeTab == MeetingFormStep.MeetingLinks) {
        this.setState({ meetingLinksOpened: true, activeTab: activeTab }, this.updateNavMenu);
      } else {
        this.setState({ activeTab: activeTab, meetingLinksOpened: false }, this.updateNavMenu);
      }
    }
    // } else {
    // this.updateNavMenu();
    // }
  };

  updateNavMenu = () => {
    let menuItems = document.querySelectorAll(
      ".sitzung-form__navigation .ui-menu .ui-menu__item"
    );
    menuItems.forEach((item) => {
      const dataTab = (item as HTMLElement).dataset.tab;
      if (this.state.meetingLinksOpened && dataTab && parseInt(dataTab) == MeetingFormStep.MeetingLinks) {
        item.setAttribute("tabindex", "0");
        return;
      }
      if (dataTab && parseInt(dataTab) == this.state.activeTab) {
        item.setAttribute("tabindex", "0");
      } else {
        item.setAttribute("tabindex", "-1");
      }
    });
  };

  getActivePhase = () => {
    if (this.state.meetingCompletedAt) {
      return MeetingPhase.MeetingLocked
    }
    if (this.state.meetingEnd && this.state.meetingEnd < new Date()) {
      return MeetingPhase.MeetingFollowUp
    }
    if (!this.state.anwesenhetslisteValid) {
      return MeetingPhase.MeetingPreparation
    }
    if (this.state.meetingFormStepStatuses[MeetingFormStep.MeetingDetails] >= MeetingFormStepStatus.Processed
      && this.state.meetingFormStepStatuses[MeetingFormStep.Agenda] >= MeetingFormStepStatus.Processed
      && this.state.meetingFormStepStatuses[MeetingFormStep.Invitations] >= MeetingFormStepStatus.Processed
    ) {
      return MeetingPhase.JustBeforeMeeting
    }
    else if (this.state.activeTab >= MeetingFormStep.MeetingDetails && this.state.activeTab < MeetingFormStep.AttendanceList) {
      return MeetingPhase.MeetingPreparation;
    } else if (this.state.activeTab == MeetingFormStep.AttendanceList && this.state.meetingFormStepStatuses[MeetingFormStep.MeetingDetails] === MeetingFormStepStatus.Processed
      && this.state.meetingFormStepStatuses[MeetingFormStep.Agenda] === MeetingFormStepStatus.Processed
      && this.state.meetingFormStepStatuses[MeetingFormStep.Invitations] === MeetingFormStepStatus.Processed) {
      return MeetingPhase.JustBeforeMeeting;
    } else if (this.state.activeTab == MeetingFormStep.MeetingFollowUp) {
      return MeetingPhase.MeetingFollowUp;
    } else {
      return MeetingPhase.Default;
    }
  }

  resetPhase = () => {
    this.setState({ secondPhaseFinished: false })
    this.loadData()
  }

  getActiveFormComponent = () => {
    const commonProps = {
      meetingId: this.state.meetingId,
      meetingHasStarted: this.meetingHasStarted,
      userPermissions: this.props.userPermissions,
      meetingCompleted: this.state.meetingCompletedAt,
      setSaveAndResetFunctions: this.setSaveAndResetFunctions,
      setDataHasChanged: this.setDataHasChanged,
      setOrderHasChanged: this.setOrderHasChanged,
      setIsLoading: this.setIsLoading,
      loadMeetingStepStatuses: this.loadMeetingStepStatuses,
      setIsFormValid: this.validateForm
    };

    if (this.state.meetingLinksOpened == true) {
      return <MeetingLinks {...commonProps} meetingStepStatus={MeetingFormStepStatus.Draft} />;
    }

    switch (this.state.activeTab) {
      case MeetingFormStep.MeetingDetails:
        return (
          <MeetingDetails
            {...commonProps}
            allSitzungs={this.props.allSitzung}
            setMeetingId={this.setMeetingId}
            setMeetingTimes={this.setMeetingTimes}
            setMeetingTitle={this.setMeetingTitle}
            meetingStepStatus={this.state.meetingFormStepStatuses[MeetingFormStep.MeetingDetails]}
            setIsFormValid={this.validateForm}
            resetPhase={this.resetPhase}
          />
        );
      case MeetingFormStep.Agenda:
        return <AgendaCreation
          gremiumId={this.props.gremiumId}
          {...commonProps}
          meetingStepStatus={this.state.meetingFormStepStatuses[MeetingFormStep.Agenda]}
          resetPhase={this.resetPhase}
        />;
      case MeetingFormStep.Invitations:
        return (
          <Invitations
            {...commonProps}
            allUsers={this.state.allUsers}
            theme={this.state.theme}
            meetingStepStatus={this.state.meetingFormStepStatuses[MeetingFormStep.Invitations]}
            resetPhase={this.resetPhase}
          />
        );
      case MeetingFormStep.AttendanceList:
        return (
          <AttendanceList
            {...commonProps}
            setAnwesenhetslisteValid={this.setAnwesenhetslisteValid}
            allUsers={this.state.allUsers}
            meetingStepStatus={this.state.meetingFormStepStatuses[MeetingFormStep.AttendanceList]}
          />
        );
      case MeetingFormStep.MeetingFollowUp:
        return <MeetingFollowUp
          {...commonProps}
          setAllChecked={this.setAllChecked}
          meetingStepStatus={this.state.meetingFormStepStatuses[MeetingFormStep.MeetingFollowUp]}
        />;
      case MeetingFormStep.MeetingLinks:
        return <MeetingLinks
          {...commonProps}
          meetingStepStatus={MeetingFormStepStatus.Draft}
        />;
    }
  };

  getNextButtonTooltip = () => {
    const suffix = this.meetingHasStarted() ? "" : " - die Sitzungsdetails werden in der App gespeichert und Sie können sie später noch ändern.";
    switch (this.state.activeTab) {
      case MeetingFormStep.MeetingDetails:
        return "Weiter zum Schritt Tagesordnung erstellen" + suffix;;
      case MeetingFormStep.Agenda:
        return "Weiter zum Schritt Einladen und Nachladen" + suffix;;
      case MeetingFormStep.Invitations:
        return "Weiter zum Schritt Anwesenheitsliste erstellen" + suffix;;
      case MeetingFormStep.AttendanceList:
        return "Alle Unterlagen vollständig? Hier gelangen Sie zum Schritt Sitzung nachbereiten.";
    }
  }

  getBackButtonTooltip = () => {
    const suffix = this.meetingHasStarted() ? "" : " - die Sitzungsdetails werden in der App gespeichert und Sie können sie später noch ändern.";
    switch (this.state.activeTab) {
      case MeetingFormStep.Agenda:
        return "Zurück zum Schritt Sitzungdetails" + suffix;
      case MeetingFormStep.Invitations:
        return "Zurück zum Schritt Tagesordnung erstellen" + suffix;
      case MeetingFormStep.AttendanceList:
        return "Zurück zum Schritt Einladen und Nachladen" + suffix;
      case MeetingFormStep.MeetingFollowUp:
        return "Zurück zum Schritt Tagesordnung erstellen" + suffix;
    }
  }

  getCompleteButtonTooltip = () => {
    switch (this.getActivePhase()) {
      case MeetingPhase.MeetingPreparation:
        return "Sitzungsvorbereitung abschließen";
      case MeetingPhase.JustBeforeMeeting:
        return "Die Anwesenheitsliste wird als Word-Datei im Sitzungskanal abgelegt. Änderungen bleiben möglich.";
      case MeetingPhase.MeetingFollowUp:
        return "Sind alle Schritte erledigt? Dann können Sie hier die Sitzung abschließen.";
    }
  }

  getCompleteConfirmMessage = () => {
    switch (this.getActivePhase()) {
      case MeetingPhase.MeetingPreparation:
        // return "Kurzfristige Änderungen oder Ergänzungen der Tagesordnung? Dann denk daran, diese im Zweifel zu Beginn der Sitzung einstimmig genehmigen zu lassen."
        return "Möchten Sie, dass ein Sitzungskanal erstellt, die Tagesordnung und das Protokoll im Kanal abgelegt und die Einladung verschickt werden? Raumänderungen, Nachladen und Ergänzungen der Tagesordnung bleiben bis zum Sitzungsbeginn möglich.";
      case MeetingPhase.JustBeforeMeeting:
        return '';
      case MeetingPhase.MeetingFollowUp:
        return "";
    }
  }

  getWarningMessage = () => {
    switch (this.state.activeTab) {
      case MeetingFormStep.MeetingDetails:
        return "Ihre Änderungen sind bisher nur in der App gespeichert. Für eine Aktualisierung der Unterlagen im Kanal klicken Sie bitte Abschließen.";
      case MeetingFormStep.Agenda:
        return "Ihre Änderungen sind bisher nur in der App gespeichert. Für eine Aktualisierung der Unterlagen im Kanal klicken Sie bitte Abschließen.";
      case MeetingFormStep.Invitations:
        return "Ihre Änderungen sind bisher nur in der App gespeichert. Für eine versenden der E-Mail klicken Sie bitte Abschließen. Denken Sie auch daran, die Anwesenheitsliste nochmal mit dem Abschließen-Button zu aktualisieren.";
      case MeetingFormStep.AttendanceList:
        return "Ihre Änderungen sind bisher nur in der App gespeichert. Für eine Aktualisierung der Unterlagen im Kanal klicken Sie bitte Abschließen.";
      case MeetingFormStep.MeetingFollowUp:
        return "";
      case MeetingFormStep.MeetingLinks:
        return "";
    }
  }

  getNavigationBulletClassName = (step: MeetingFormStep) => {

    const stepStatus = this.state.meetingFormStepStatuses[step];
    //First tab at least need to have a grey ckeckmark
    if (step === MeetingFormStep.MeetingDetails && stepStatus == MeetingFormStepStatus.Draft) { return "status__ready"; }

    switch (stepStatus) {
      case MeetingFormStepStatus.Draft: { return "status__draft"; }
      case MeetingFormStepStatus.ReadyForProcessing: { return "status__ready"; }
      case MeetingFormStepStatus.Processed: { return "status__processed"; }
      case MeetingFormStepStatus.NeedsReprocessing: { return "status__needs-reprocessing"; }
      default: { return "" };
    }
  }

  isNextBtnVisible = () => {
    if (this.state.activeTab == MeetingFormStep.MeetingFollowUp) { return false; }
    return true;
  };

  isBackBtnVisible = () => {
    if (this.state.activeTab == MeetingFormStep.MeetingDetails) {
      return false;
    }
    return true;
  };

  isTabAvailable = (tab: MeetingFormStep) => {
    const meetingPhase = this.getMeetingPhase();
    if (meetingPhase === MeetingPhase.JustBeforeMeeting && this.state.firstPhaseFinished) {
      this.setState({ firstPhaseFinished: false, secondPhaseFinished: false })
    }

    if (tab == MeetingFormStep.MeetingLinks && meetingPhase > MeetingPhase.MeetingPreparation) {
      return true;
    }

    if (
      tab < MeetingFormStep.MeetingDetails ||
      tab > MeetingFormStep.MeetingFollowUp
    ) {
      return false;
    }

    switch (meetingPhase) {
      case MeetingPhase.MeetingLocked: {
        return true;
      }
      case MeetingPhase.MeetingFollowUp: {
        return true;
      }
      case MeetingPhase.JustBeforeMeeting: {
        if (tab <= MeetingFormStep.AttendanceList) {
          return true;
        } else {
          return false;
        }
      }
      case MeetingPhase.MeetingPreparation: {
        switch (tab) {
          case MeetingFormStep.MeetingDetails: {
            return true;
          }
          case MeetingFormStep.Agenda: {
            return true;
          }
          case MeetingFormStep.Invitations: {
            if (
              this.state.meetingFormStepStatuses[MeetingFormStep.Agenda] >
              MeetingFormStepStatus.Draft
            ) {
              return true;
            } else {
              return false;
            }
          }
          case MeetingFormStep.AttendanceList: {
            if (
              this.state.firstPhaseFinished
            ) {
              return true;
            } else {
              return false;
            }
          }
          case MeetingFormStep.MeetingFollowUp: {
            if (
              this.state.secondPhaseFinished && this.state.meetingStart! > new Date()
            ) {
              return true;
            } else {
              return false;
            }
          }
          default: {
            return false;
          }
        }
      }
    }
  }

  isCompleteBtnEnabled = () => {
    return false;
  };

  getMeetingPhase = () => {
    if (this.meetingHasCompletedAt()
      && this.state.meetingFormStepStatuses[MeetingFormStep.MeetingFollowUp] >= MeetingFormStepStatus.Processed
      && this.state.meetingFormStepStatuses[MeetingFormStep.AttendanceList] >= MeetingFormStepStatus.Processed
      && this.state.meetingFormStepStatuses[MeetingFormStep.Invitations] >= MeetingFormStepStatus.Processed
      && this.state.meetingFormStepStatuses[MeetingFormStep.Agenda] >= MeetingFormStepStatus.Processed
      && this.state.meetingFormStepStatuses[MeetingFormStep.MeetingDetails] >= MeetingFormStepStatus.Processed) {
      return MeetingPhase.MeetingLocked;
    } else if (this.state.meetingFormStepStatuses[MeetingFormStep.MeetingFollowUp] <= MeetingFormStepStatus.ReadyForProcessing
      && this.state.meetingFormStepStatuses[MeetingFormStep.AttendanceList] >= MeetingFormStepStatus.Processed
      && this.state.meetingFormStepStatuses[MeetingFormStep.Invitations] >= MeetingFormStepStatus.Processed
      && this.state.meetingFormStepStatuses[MeetingFormStep.Agenda] >= MeetingFormStepStatus.Processed
      && this.state.meetingFormStepStatuses[MeetingFormStep.MeetingDetails] >= MeetingFormStepStatus.Processed) {
      return MeetingPhase.MeetingFollowUp;
    } else if (this.state.meetingFormStepStatuses[MeetingFormStep.MeetingDetails] >= MeetingFormStepStatus.Processed
      && this.state.meetingFormStepStatuses[MeetingFormStep.Agenda] >= MeetingFormStepStatus.Processed
      && this.state.meetingFormStepStatuses[MeetingFormStep.Invitations] >= MeetingFormStepStatus.Processed) {
      return MeetingPhase.JustBeforeMeeting;
    } else if (this.state.meetingFormStepStatuses[MeetingFormStep.MeetingDetails] <= MeetingFormStepStatus.ReadyForProcessing
      || this.state.meetingFormStepStatuses[MeetingFormStep.Agenda] <= MeetingFormStepStatus.ReadyForProcessing
      || this.state.meetingFormStepStatuses[MeetingFormStep.Invitations] <= MeetingFormStepStatus.ReadyForProcessing) {
      return MeetingPhase.MeetingPreparation;
    } else {
      return MeetingPhase.Default;
    }
  }

  isPhaseFinishable = (phase: MeetingPhase) => {
    switch (phase) {
      case MeetingPhase.MeetingPreparation: {
        if ((this.state.meetingFormStepStatuses[MeetingFormStep.MeetingDetails] != MeetingFormStepStatus.Processed
          || this.state.meetingFormStepStatuses[MeetingFormStep.Agenda] != MeetingFormStepStatus.Processed
          || this.state.meetingFormStepStatuses[MeetingFormStep.Invitations] != MeetingFormStepStatus.Processed)
          && (this.state.meetingFormStepStatuses[MeetingFormStep.MeetingDetails] != MeetingFormStepStatus.Draft
            && this.state.meetingFormStepStatuses[MeetingFormStep.Agenda] != MeetingFormStepStatus.Draft
            && this.state.meetingFormStepStatuses[MeetingFormStep.Invitations] != MeetingFormStepStatus.Draft)) {
          return true;
        } else {
          return false;
        }
      } case MeetingPhase.JustBeforeMeeting: {
        if (this.state.dataHasChanged && !this.state.stepClicked.attendance) {
          return true;
        } else {
          return false;
        }
      } case MeetingPhase.MeetingFollowUp: {
        if (this.state.meetingFormStepStatuses[MeetingFormStep.MeetingFollowUp] == MeetingFormStepStatus.ReadyForProcessing) {
          return true;
        } else {
          return false;
        }
      } default: {
        return false;
      }
    }
  }

  finishPhaseWrapper = async () => {
    if (!this.state.isFormValid) {
      return
    }
    if (this.getActivePhase() === MeetingPhase.MeetingPreparation) {
      this.state.confirmDialogRef.current.showConfirmDialog(
        this.state.activeTab === MeetingFormStep.Agenda ?
          "Kurzfristige Änderungen oder Ergänzungen der Tagesordnung? Dann denk daran, diese im Zweifel zu Beginn der Sitzung einstimmig genehmigen zu lassen."
          : this.getCompleteConfirmMessage(),
        async () => {
          this.setPhaseFinishing(true);
          await this.finishPhase();
          this.setPhaseFinishing(false);
        }
      );
    } else {
      this.setPhaseFinishing(true);
      this.setState({ phaseTwoAlert: true })
      await this.finishPhase();
      this.setPhaseFinishing(false);
      this.setState({ dataHasChanged: false })
    }
  }

  validateBeforeNextTab = () => {
    if (!this.state.isFormValid && this.state.meetingId == Guid.createEmpty().toString()) {
      // this.state.confirmDialogRef.current.showConfirmDialog(
      //   'Sie haben noch keinen Sitzungsraum ausgewählt. Möchten Sie trotzdem fortfahren?',
      //   async () => {
      //     await this.nextTab()
      //     await this.setState({ isFormValid: true })
      //   }
      // );
    } else {
      this.nextTab()
    }
  }
  validateBeforeChangingTab = () => {
    if (!this.state.isFormValid && this.state.meetingId == Guid.createEmpty().toString()) {
      // this.state.confirmDialogRef.current.showConfirmDialog(
      //   'Sie haben noch keinen Sitzungsraum ausgewählt. Möchten Sie trotzdem fortfahren?',
      //   async () => {
      //     await this.changeTab
      //     this.setState({ isFormValid: true })
      //     return true
      //   }
      // );
    } else {
      return false
    }
  }

  validateForm = (isValid: boolean) => {
    this.setState({
      isFormValid: isValid
    })
  }

  finishPhase = async () => {
    let response;
    let meetingLinksOpened = false;
    if (this.getActivePhase() == MeetingPhase.MeetingPreparation) {
      //finish phase 1
      response = await finishMeetingPhase(this.state.meetingId, MeetingPhase.MeetingPreparation);
      if (response.status === StatusCodes.OK) {
        this.setState({ activeTab: 5, firstPhaseFinished: true })
      } else {
        if (!response.response.data.UserFriendlyMessage) {
          this.setState({ phaseFinishFailed: true })
        }
        return
      }
      if (this.state.meetingFormStepStatuses[MeetingFormStep.Invitations] == MeetingFormStepStatus.NeedsReprocessing
        && this.state.meetingFormStepStatuses[MeetingFormStep.AttendanceList] == MeetingFormStepStatus.Processed) {
        //Set attendance list state to NeedsReprocessing
        const attendanceListResponse = await getAttendanceList(this.state.meetingId);
        const attendanceListId = attendanceListResponse.data[0].id;
        const patchAttendanceListStatusResponse = await patchAttendanceList(attendanceListId, 'anwesenheitslistenstatus', MeetingFormStepStatus.NeedsReprocessing);
        if (patchAttendanceListStatusResponse.status != StatusCodes.OK) {
          response = patchAttendanceListStatusResponse;
        }
      }
      meetingLinksOpened = true;
    } else if (this.getActivePhase() == MeetingPhase.JustBeforeMeeting) {
      //finish phase 2
      response = await finishMeetingPhase(this.state.meetingId, MeetingPhase.JustBeforeMeeting);
      if (response.status === StatusCodes.OK) {
        const attendanceListResponse = await getAttendanceList(this.state.meetingId);
        const attendanceListId = attendanceListResponse.data[0].id;
        await patchAttendanceList(attendanceListId, 'anwesenheitslistenstatus', MeetingFormStepStatus.Processed);
        this.setState({ secondPhaseFinished: true, activeTab: 5 })
      } else {
        if (!response.response.data.UserFriendlyMessage) {
          this.setState({ phaseFinishFailed: true })
        }
        return
      }
      meetingLinksOpened = true;
    } else if (this.getActivePhase() == MeetingPhase.MeetingFollowUp) {
      //finish phase 3
      response = await finishMeetingPhase(this.state.meetingId, MeetingPhase.MeetingFollowUp);
      if (!response.response.data.UserFriendlyMessage) {
        this.setState({ phaseFinishFailed: true })
      }
      meetingLinksOpened = false;
      return
    } else {
      return false;
    }

    if (response && response.status == StatusCodes.OK) {
      this.loadMeetingStepStatuses();
      this.setState({
        meetingLinksOpened: meetingLinksOpened,
        isLoading: false,
      }, this.updateNavMenu);
      return true;
    } else {
      this.setState({ isLoading: false })
      return false;
    }
  }

  setAllChecked = (checked) => {
    this.setState({allChecked: checked} )
  }

  finishPhaseButtonDisabledCheck = (activeTab) => {
    if (this.state.meetingCompletedAt) {
      return true
    }
    if (activeTab === MeetingFormStep.MeetingFollowUp && (!this.state.allChecked)) {
      return true
    }
    if ((!this.isPhaseFinishable(this.getActivePhase()) ||
      this.state.isLoading ||
      !this.state.isFormValid)) {
      return true
    }
    if (activeTab < MeetingFormStep.AttendanceList && this.getActivePhase() === MeetingPhase.JustBeforeMeeting) {
      return true
    }

  }

  showButtons = () => {
    const activeTab = this.state.activeTab
    if (activeTab === 5) return this.isBackBtnVisible() && <TooltipHost content={"Zurück zum letzten Schritt. Sie gelangen auch über das linke Menü direkt dorthin, wo Sie weitermachen möchten."}><Button onClick={this.previousTab} >Zurück</Button></TooltipHost>
    // back button
    return <>{this.isBackBtnVisible() && <TooltipHost content={!this.isTabAvailable(this.state.activeTab - 1) ? "" : this.getBackButtonTooltip()}><Button disabled={!this.isTabAvailable(this.state.activeTab - 1) || this.state.isLoading} onClick={this.previousTab} >Zurück</Button></TooltipHost>}
      {/* next and end button */}
      {this.isNextBtnVisible() &&
        <TooltipHost content={!this.isTabAvailable(this.state.activeTab + 1) ? "" : this.getNextButtonTooltip()}>
          <Button
            disabled={!this.isTabAvailable(this.state.activeTab + 1)
              || this.state.isLoading
              || !this.state.isFormValid}
            onClick={this.state.activeTab === 0
              ? this.validateBeforeNextTab : this.nextTab} appearance="primary" >
            Weiter
          </Button>
        </TooltipHost>}
       {/* complete button */}
       <TooltipHost
        content={!this.isPhaseFinishable(this.getActivePhase()) ? "" : this.getCompleteButtonTooltip()}>
         <Button
        disabled={this.finishPhaseButtonDisabledCheck(activeTab)}
        onClick={activeTab === MeetingFormStep.MeetingFollowUp ? this.onCloseClick : this.finishPhaseWrapper} appearance="primary" >
        Abschließen
      </Button>
      </TooltipHost>
    </>
  }

  render = () => {
    return (
      <div className="sitzung-form__wrapper" >
        <ConfirmDialog ref={this.state.confirmDialogRef} />
        <ApiAlertDialog
          errorType={this.state.apiErrorType}
          isDialogHidden={!this.state.apiErrorDialogShow}
          message={this.state.apiAlertMessage}
          closeFunction={() => this.setState({ apiErrorDialogShow: false })} />
        <AlertDialog
          isDialogHidden={!this.state.sitzungCompleted}
          message="Diese Sitzung ist abgeschlossen."
          closeFunction={() => this.setState({ sitzungCompleted: false })}
        />
        <ApiAlertDialog
          closeFunction={() => this.setState({ phaseFinishFailed: false })}
          errorType={ErrorType.Error}
          isDialogHidden={!this.state.phaseFinishFailed}
          message={GenericApiFailMessage} />
        <AlertDialog
          isDialogHidden={!this.state.agendaDialogShow}
          message="Kurzfristige Änderungen oder Ergänzungen der Tagesordnung? Dann denk daran, diese im Zweifel zu Beginn der Sitzung einstimmig genehmigen zu lassen."
          closeFunction={() => this.setState({ agendaDialogShow: false })}
        />
        <AlertDialog
          isDialogHidden={!this.state.phaseTwoAlert}
          message='Sie haben alle wichtigen Schritte erledigt. Die Sitzung kann starten. Kommen Sie einfach zurück in die App, wenn Sie vorher noch etwas ändern oder ergänzen möchten, oder nach der Sitzung für die Checkliste "Sitzung nachbereiten".'
          closeFunction={() => this.setState({ phaseTwoAlert: false })}
        />
        <div className="sitzung-form__header">
          {/* <StatusBar notification={this.state.notification} /> */}
          <Text style={{ fontWeight: "bold", fontSize: "2.5rem" }} as='h1'>{this.state.meetingTitle}</Text>
          {/* <MeetingPhaseDisplay meetingPhase={this.getMeetingPhase()} /> */}
          <Stepper steps={stepStatusNames} activeStep={this.getMeetingPhase()} />
        </div>
        <div className="meeting-form-dialog-container">
          <div className="sitzung-form__navigation">
            <MenuList className='sitzung-form__nav-menu' style={{ marginTop: 5, width: 230 }}>
              <MenuItem
                className={`${this.state.activeTab === 0 ? "menu-item-active" : ""}`}
                onClick={() => this.changeTab(0)}
                disabled={this.state.isPhaseFinishing || !this.isTabAvailable(MeetingFormStep.MeetingDetails)}
                data-tab={MeetingFormStep.MeetingDetails}>
                <div className="menu-inline">
                  <div className={!this.state.stepClicked.details ? "checkmark-hide" : "checkmark-show"}>
                    <CheckIcon />
                  </div>
                  Sitzungsdetails
                </div>
              </MenuItem>
              <MenuItem
                className={`${this.state.activeTab === 1 ? "menu-item-active" : ""}`}
                onClick={() => this.changeTab(1)}
                disabled={this.state.isPhaseFinishing || !this.isTabAvailable(MeetingFormStep.Agenda)}
                data-tab={MeetingFormStep.Agenda}>
                <div className="menu-inline">
                  <div className={!this.state.stepClicked.agenda ? "checkmark-hide" : "checkmark-show"} >
                    <CheckIcon />
                  </div>
                  Tagesordnung und Protokoll
                </div>
              </MenuItem>
              <MenuItem
                className={`${this.state.activeTab === 2 ? "menu-item-active" : ""}`}
                onClick={() => this.changeTab(2)}
                disabled={this.state.isPhaseFinishing || !this.isTabAvailable(MeetingFormStep.Invitations)}
                data-tab={MeetingFormStep.Invitations}>
                <div className="menu-inline" >
                  <div className={!this.state.stepClicked.invitations ? "checkmark-hide" : "checkmark-show"} >
                    <CheckIcon />
                  </div>
                  Einladen und Nachladen
                </div>
              </MenuItem>
              <MenuItem
                className={`${this.state.activeTab === 3 ? "menu-item-active" : ""}`}
                onClick={() => this.changeTab(3)}
                disabled={this.state.isPhaseFinishing || !this.isTabAvailable(MeetingFormStep.AttendanceList)}
                data-tab={MeetingFormStep.AttendanceList}>
                <div className="menu-inline">
                  <div className={!this.state.stepClicked.attendance ? "checkmark-hide" : "checkmark-show"} >
                    <CheckIcon />
                  </div>
                  Anwesenheitsliste
                </div>
              </MenuItem>
              <MenuItem
                className={`${this.state.activeTab === 4 ? "menu-item-active" : ""}`}
                onClick={() => this.changeTab(4)}
                disabled={this.state.isPhaseFinishing || !this.isTabAvailable(MeetingFormStep.MeetingFollowUp)}
                data-tab={MeetingFormStep.MeetingFollowUp}>
                <div className="menu-inline">
                  <div className={!this.state.meetingCompletedAt ? "checkmark-hide" : "checkmark-show"}>
                    <CheckIcon />
                  </div>
                  Sitzung nachbereiten
                </div>
              </MenuItem>
              <hr style={{ "width": "100%" }} />
              <MenuItem
                className={`${this.state.activeTab === 5 ? "menu-item-active" : ""}`}
                onClick={() => this.changeTab(5)}
                disabled={this.state.isPhaseFinishing || !this.isTabAvailable(MeetingFormStep.MeetingLinks)}
                data-tab={MeetingFormStep.MeetingLinks}>
                <div className="menu-inline">
                  <div>
                    <MeetingLinksIcon />
                  </div>
                  Sitzungsunterlagen prüfen
                </div>

              </MenuItem>
            </MenuList>
          </div>
          <div className="sitzung-form__content" style={{ padding: "5px 20px", width: "100%", overflow: "scroll" }}>
            {(this.state.isLoading || this.state.isPhaseFinishing) && <Spinner className="spinner" style={{
              width: "100%",
              height: "100vh",
              position: "fixed",
              top: "0",
              left: "0",
            }} label={LoaderMessage} />}
            <div hidden={this.state.isLoading || this.state.isPhaseFinishing}>{this.getActiveFormComponent()}</div>
          </div>
        </div>
        <div className={this.state.activeTab === MeetingFormStep.MeetingFollowUp ? "sitzung-form__footer-last" : "sitzung-form__footer"}>
          {
            this.showButtons()}
        </div>
      </div>
    );
  };
}

export default MeetingForm;
