import * as React from "react";
import './dialog.scss';
import { Dialog, DialogBody, DialogSurface, DialogTitle, DialogContent, Button, FluentProvider, teamsDarkTheme, teamsLightTheme, teamsHighContrastTheme } from "@fluentui/react-components";
import { ErrorType } from "../../../enums/errorType";
import { ErrorIcon, InfoIconLarge, WarningIcon } from "../../../utils/icons";
import { app } from "@microsoft/teams-js";
import { Themes } from "../../../constants/constants";

interface IApiAlertDialogProps {
    isDialogHidden: boolean;
    message: string;
    closeFunction: Function;
    errorType: ErrorType
}

interface IApiAlertDialogState {
    dialogMessage: string;
    theme: string;
}

export default class ApiAlertDialog extends React.Component<IApiAlertDialogProps, IApiAlertDialogState>{
    constructor(props: any) {
        super(props);
        this.state = {
            dialogMessage: "",
            theme: '',
        }
    }

    onConfirmClick = () => {
        this.props.closeFunction();
    }

    componentDidMount(): void {
        app.getContext().then((context) => {
            this.setState({ theme: context.app.theme! });
        });
    }



    render() {
        const light = teamsLightTheme;
        const dark = teamsDarkTheme;
        const contrast = teamsHighContrastTheme;
        return (
            <FluentProvider theme={this.state.theme === Themes.contrast ? contrast : this.state.theme === Themes.dark ? dark : light}>
                <Dialog
                    open={!this.props.isDialogHidden}
                >
                    <DialogSurface className="surface confirm-dialog">
                        <DialogTitle className="dialog-title">
                            <div>{this.props.errorType === ErrorType.Information && <div className="icon-scale"><InfoIconLarge /></div>}
                                {this.props.errorType === ErrorType.Warning && <div className="error-icon-title icon-warning icon-scale"><WarningIcon /></div>}
                                {this.props.errorType === ErrorType.Error && <div className="error-icon-title icon-error icon-scale"><ErrorIcon /></div>}</div>
                            <div><div className="dialog-title-text">
                                {this.props.errorType === ErrorType.Information && <span>Information</span>}
                                {this.props.errorType === ErrorType.Warning && <span>Warnung</span>}
                                {this.props.errorType === ErrorType.Error && <span>Fehler</span>}
                                <span className="dialog-title-message">{this.props.message}</span></div></div>

                        </DialogTitle>
                        <DialogBody>
                            <DialogContent style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "end",

                            }}>
                                <Button appearance="primary" onClick={this.onConfirmClick}>Ok</Button>
                            </DialogContent>
                        </DialogBody>
                    </DialogSurface>
                </Dialog >
            </FluentProvider>
        );
    }
}