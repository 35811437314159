import * as React from "react";
import './dialog.scss';
import { Dialog, DialogSurface, DialogTitle, Button, DialogBody } from "@fluentui/react-components";

interface IAlertDialogProps {
    isDialogHidden: boolean;
    message: string;
    closeFunction: Function;
}

interface IAlertDialogState {
    dialogMessage: string;
}

export default class AlertDialog extends React.Component<IAlertDialogProps, IAlertDialogState>{
    constructor(props: any) {
        super(props);
        this.state = {
            dialogMessage: "",
        }
    }

    onConfirmClick = () => {
        this.props.closeFunction();
    }
  
    render() {
        return (
            <Dialog
                open={!this.props.isDialogHidden}
            >
                <DialogSurface className="alert-dialog">
                   <DialogBody style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                   <DialogTitle style={{fontSize: '1.5rem'}}>{this.props.message}</DialogTitle>
                    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'end', alignItems: 'end'}}>
                    <Button appearance="primary" onClick={this.onConfirmClick}>Ok</Button>
                    </div>
                   </DialogBody>
                </DialogSurface>
            </Dialog>
        );
    }
}