import { Text, Field, Input, Dropdown, Option, Tooltip, Persona } from "@fluentui/react-components";
import React from "react";
import ISiztungFormBaseProps from "./sitzung-form-base-props";
import { StatusCodes } from "http-status-codes";
import IGraphUser from "../../../models/graph-user";
import IAnwesenheitsliste from "../../../models/anwesenheit/anwesenheitsliste";
import { Guid } from "guid-typescript";
import { EinladungsrückmeldungStatus } from "../../../enums/einladungsrückmeldungstatus.enum";
import { Teilnahmeform } from "../../../enums/teilnahmeform.enum";
import { Verhinderungsgrund } from "../../../enums/verhinderungsgrund.enum";
import { userHasPermission } from "../../../Helpers/permissionHelper";
import { cloneDeep, isEqual } from "lodash";
import ISitzungFormBaseState from "./sitzung-form-base-state";
import { AbsenceReasonNeeded, RequiredFieldErrorMessage, TeilnahmeFormLabel, TeilnahmeLabel, TooltipAttendanceList } from "../../../constants/textLabels";
import {
  getAttendanceList,
  patchAttendanceList,
  postAttendanceList,
} from "../../../api/attendance-list-dto-api";
import { IMeetingFormBaseFunctions } from "./sitzung-form-base-functions";
import { MeetingFormStepStatus } from "../../../enums/meeting-form-step-status.enum";
import { getGremiumKonfig } from "../../../api/konfiguration-api";
import { handleTokenAccessFailure } from "../../../Helpers/apiHelper";
import { getAddresses, patchAddress, putAddress } from "../../../api/invitations-dto-api";
import "./attendance-list.scss"
import { getMeeting } from "../../../api/meeting-dto-api";
import { CustomTooltip } from "../../common/CustomTooltip/CustomTooltip";

interface IAttendanceListProps extends ISiztungFormBaseProps {
  allUsers: IGraphUser[];
  setIsFormValid(isValid: boolean);
  setAnwesenhetslisteValid(valid: boolean)
}

interface IAttendanceListState extends ISitzungFormBaseState {
  attendanceList: IAnwesenheitsliste;
  attendanceListInitial: IAnwesenheitsliste;
  attendanceListPrev: IAnwesenheitsliste;
  typingTimer: NodeJS.Timeout | undefined;
}

const rückmeldungVerhinderungsgrundType = [
  {
    id: Number(Verhinderungsgrund.Krankheit_Kur_Elternzeit),
    type: "Krankheitsbedingte Arbeitsunfähigkeit/Elternzeit/Kur",
  },
  { id: Number(Verhinderungsgrund.Urlaub), type: "Erholungsurlaub" },
  {
    id: Number(Verhinderungsgrund.Montage_Dienstreise),
    type: "Dienstreise/Montage",
  },
  {
    id: Number(Verhinderungsgrund.Schulung_Fortbildung),
    type: "Schulung/Fortbildung",
  },
  {
    id: Number(Verhinderungsgrund.Sonstige),
    type: "sonstiger dringender Verhinderungsgrund",
  },
  { id: Number(Verhinderungsgrund.Keine), type: "Kein Verhinderungsgrund" }
];

class AttendanceList
  extends React.Component<IAttendanceListProps, IAttendanceListState>
  implements IMeetingFormBaseFunctions {
  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: false,
      showLoader: false,
      attendanceList: {
        id: Guid.EMPTY,
        sitzungId: this.props.meetingId,
        name: "",
        anwesenheitslisteneinträge: [],
        anwesenheitslistenstatus: MeetingFormStepStatus.Draft,
      },
      attendanceListInitial: {
        id: Guid.EMPTY,
        sitzungId: this.props.meetingId,
        name: "",
        anwesenheitslisteneinträge: [],
        anwesenheitslistenstatus: MeetingFormStepStatus.Draft,
      },
      attendanceListPrev: {
        id: Guid.EMPTY,
        sitzungId: this.props.meetingId,
        name: "",
        anwesenheitslisteneinträge: [],
        anwesenheitslistenstatus: MeetingFormStepStatus.Draft,
      },
      typingTimer: undefined,
    };
  }

  componentDidMount = async () => {
    //this.props.setDataHasChanged(false)
    this.props.setIsLoading(true);
    await this.loadData();
    // this.props.setSaveAndResetFunctions(this.saveDraft);
    // await this.props.loadMeetingStepStatuses();
    this.props.setIsLoading(false);
  }

  loadData = async () => {
    const getAttendanceListResponse = await getAttendanceList(
      this.props.meetingId
    );

    const getInvitationAnswersResponse = await getAddresses(this.props.meetingId)

    let attendanceList = this.state.attendanceList;
    attendanceList.sitzungId = this.props.meetingId;
    let saveAttendanceList = false;

    if (
      getAttendanceListResponse.status === StatusCodes.OK &&
      getInvitationAnswersResponse.status === StatusCodes.OK
    ) {
      if (getAttendanceListResponse.data[0]) {
        attendanceList = getAttendanceListResponse.data[0];
        attendanceList.anwesenheitslisteneinträge =
          getInvitationAnswersResponse.data;
        saveAttendanceList = true;
      } else {
        const getConfig = await getGremiumKonfig(localStorage.getItem("selectedAuschuss")!, handleTokenAccessFailure)
        if (getConfig.status === StatusCodes.OK) {
          attendanceList.name = getConfig.data.anwesenheitslistenname;
          attendanceList.anwesenheitslistenstatus =
            MeetingFormStepStatus.ReadyForProcessing;
          const postAttendanceListResponse =
            await postAttendanceList(attendanceList);
          if (postAttendanceListResponse.status === StatusCodes.OK) {
            attendanceList = postAttendanceListResponse.data;
            attendanceList.anwesenheitslisteneinträge = getInvitationAnswersResponse.data;
            saveAttendanceList = true;
          }
        }
      }
    }

    if (saveAttendanceList) {
      this.setState({
        attendanceList: attendanceList,
        attendanceListInitial: cloneDeep(attendanceList),
        attendanceListPrev: cloneDeep(attendanceList)
      }, this.validateResponses);
    }
  };

  validateTeilnahmeFormat = (i) => {
    if (this.state.attendanceList.anwesenheitslisteneinträge[i].response === EinladungsrückmeldungStatus.Zusage &&
      !this.state.attendanceList.anwesenheitslisteneinträge[i].participationForm
    ) {
      return false
    } else {
      return true
    }
  }

  onAttendanceStatusChange = async (e, v, i) => {
    let attendanceList = this.state.attendanceList;
    let attendanceListPrev = this.state.attendanceListPrev;
    let attendanceFormat
    const attendanceStatus = !v.optionValue
      ? EinladungsrückmeldungStatus.Keine
      : EinladungsrückmeldungStatus[
      v.optionValue as keyof typeof EinladungsrückmeldungStatus
      ];
    if (attendanceStatus === EinladungsrückmeldungStatus.Zusage) {

      const response = await getMeeting(this.props.meetingId, handleTokenAccessFailure)
      if (response.data) {
        const responseType = response.data.sitzungsformat
        attendanceFormat = responseType
        attendanceList.anwesenheitslisteneinträge[i].participationForm = responseType;
        if (responseType === 3) {
          attendanceList.anwesenheitslisteneinträge[i].participationForm = Teilnahmeform.Präsenz;
          attendanceFormat = Teilnahmeform.Präsenz
        }
      }
      let usersResponse = this.state.attendanceList.anwesenheitslisteneinträge[i];
      await patchAddress(usersResponse.id!, "participationForm", attendanceFormat)
    }
    if (attendanceStatus !== attendanceList.anwesenheitslisteneinträge[i].response) {
      if (this.isFormValid()){
        this.props.setDataHasChanged(true);
      }
    }
    const attendanceStatusPrev =
      attendanceList.anwesenheitslisteneinträge[i].response;
    attendanceList.anwesenheitslisteneinträge[i].response = attendanceStatus;
    attendanceListPrev.anwesenheitslisteneinträge[i].response =
      attendanceStatusPrev;
    this.setState(
      {
        attendanceList: attendanceList,
        attendanceListPrev: attendanceListPrev,
      },
      async() => {
        await this.saveDraft("response", { index: i, value: attendanceStatus });
        await this.props.loadMeetingStepStatuses()
        await this.loadData()
      }

    );
  };

  onAttendanceFormatChange = (e, v, i) => {
    let attendanceList = this.state.attendanceList;
    let attendanceListPrev = this.state.attendanceListPrev;
    const attendanceFormat = !v.optionValue
      ? Teilnahmeform.Default
      : Teilnahmeform[v.optionValue as keyof typeof Teilnahmeform];
    if (attendanceFormat !== attendanceList.anwesenheitslisteneinträge[i].participationForm) {
      if (this.isFormValid())
        this.props.setDataHasChanged(true);
    }
    const attendanceFormatPrev =
      attendanceList.anwesenheitslisteneinträge[i].participationForm;
    attendanceList.anwesenheitslisteneinträge[i].participationForm =
      attendanceFormat;
    attendanceListPrev.anwesenheitslisteneinträge[i].participationForm =
      attendanceFormatPrev;
    this.setState(
      {
        attendanceList: attendanceList,
        attendanceListPrev: attendanceListPrev,
      },
      async() =>{
        await this.saveDraft("participationForm", {
          index: i,
          value: attendanceFormat,
        })
        await this.props.loadMeetingStepStatuses()
        await this.loadData()
      }
    );
  };

  onAbsenceReasonChange = (e, v, i) => {
    let attendanceList = this.state.attendanceList;
    let attendanceListPrev = this.state.attendanceListPrev;
    let absenceReason = parseInt(v.optionValue)
    if (absenceReason !== attendanceList.anwesenheitslisteneinträge[i].declineReason) {
      if (this.isFormValid())
        this.props.setDataHasChanged(true);
    }
    const absenceReasonPrev = attendanceList.anwesenheitslisteneinträge[i].declineReason;
    attendanceList.anwesenheitslisteneinträge[i].declineReason = absenceReason;
    attendanceListPrev.anwesenheitslisteneinträge[i].declineReason = absenceReasonPrev;
    // const validationState = this.validateAbsenceReason(i)
    // if (!validationState) {
    //   this.props.setIsFormValid(false)
    // } else {
    //   this.props.setIsFormValid(true)
    // }
    this.setState(
      {
        attendanceList: attendanceList,
        attendanceListPrev: attendanceListPrev,
      },
      async() =>{
        await this.saveDraft("declineReason", {
          index: i,
          value: absenceReason,
        })
        await this.props.loadMeetingStepStatuses()
        await this.loadData()
      })
  };

  onNoticeChange = (e, i) => {
    let attendanceList = this.state.attendanceList;
    let attendanceListPrev = this.state.attendanceListPrev;
    const notice = e.target.value;
    const noticePrev = attendanceList.anwesenheitslisteneinträge[i].responseComment;
    attendanceList.anwesenheitslisteneinträge[i].responseComment = notice;

    if (!this.state.typingTimer) {
      attendanceListPrev.anwesenheitslisteneinträge[i].responseComment = noticePrev;
      this.setState({ attendanceListPrev: attendanceListPrev });
    }

    this.setState({ attendanceList: attendanceList }, () => {
      if (this.state.typingTimer) {
        clearTimeout(this.state.typingTimer);
      }
      this.setState({
        typingTimer: setTimeout(async () => {
          await this.saveDraft("responseComment", { index: i, value: notice });
          await this.props.loadMeetingStepStatuses()
          await this.loadData()
        }, 1000),
      });
    });
    if (this.isFormValid())
      this.props.setDataHasChanged(true);
  };

  // setDataHasChanged = () => {
  //   if (isEqual(this.state.attendanceList, this.state.attendanceListInitial)) {
  //     this.props.setDataHasChanged(false);
  //   } else {
  //     this.props.setDataHasChanged(true);
  //   }
  // };

  isFormValid = (field: string = "") => {
    return true;
  };

  validateResponses = () => {
    if (this.state.attendanceList.anwesenheitslisteneinträge.filter((a) => !a.response || a.response === 0).length > 0) {
      this.props.setAnwesenhetslisteValid(false)
      this.props.setIsFormValid(false)
    }
    else if ((this.state.attendanceList.anwesenheitslisteneinträge.filter((a) => a.response === 1 && (!a.participationForm || a.participationForm === 0))).length > 0) {
      this.props.setAnwesenhetslisteValid(false)
      this.props.setIsFormValid(false)
    }
    else if ((this.state.attendanceList.anwesenheitslisteneinträge.filter((a) => a.response === 2 && (a.declineReason === null))).length > 0) {
      this.props.setAnwesenhetslisteValid(false)
      this.props.setIsFormValid(false)
    }
    else {
      this.props.setAnwesenhetslisteValid(true)
      this.props.setDataHasChanged(true)
      this.props.setIsFormValid(true)
    }
  }

  isFormDisabled(): boolean {
    const ret = !userHasPermission(
      this.props.userPermissions,
      "AnwesenheitslisteUpdate"
    )
      ||
     (this.props.meetingCompleted ? true : false)

    return ret;
  }

  saveDraft = async (
    propName: string | undefined = undefined,
    propValue: any = undefined,
    showLoader: boolean = false
  ) => {
    if (showLoader) { this.props.setIsLoading(true); }
    this.validateResponses()
    let httpResponse;
    if (isEqual(this.state.attendanceList, this.state.attendanceListPrev)) {
      if (showLoader) { this.props.setIsLoading(false); }
      return true;
    } else {
      if (
        propName != undefined &&
        propValue != undefined &&
        this.props.meetingId != Guid.EMPTY
      ) {
        let usersResponse = this.state.attendanceList.anwesenheitslisteneinträge[propValue.index];
        httpResponse = await patchAddress(usersResponse.id!, propName, propValue.value);
      } else {
        let promiseArray = this.state.attendanceList.anwesenheitslisteneinträge.map(resp => {
          return putAddress(resp.id!, resp);
        });
        const httpResponses = await Promise.all(promiseArray);
        httpResponse = httpResponses[0];
        httpResponses.forEach(resp => {
          if (resp.status != StatusCodes.OK) {
            httpResponse = resp;
          }
        });
      }

      if (this.state.attendanceList.anwesenheitslistenstatus !== MeetingFormStepStatus.ReadyForProcessing) {
        const patchAttendanceListStatusResponse = await patchAttendanceList(this.state.attendanceList.id, 'anwesenheitslistenstatus', MeetingFormStepStatus.Draft);
        if (patchAttendanceListStatusResponse.status != StatusCodes.OK) {
          httpResponse = patchAttendanceListStatusResponse;
        }
      }

    }

    if (showLoader) { this.props.setIsLoading(false); }
    if (httpResponse.status == StatusCodes.OK) {
      return true;
    } else {
      window.alert("Erstellen der Anwesenheitsliste Entwurf ist fehlgeschlagen.");
      this.setState({ attendanceList: cloneDeep(this.state.attendanceListPrev) });
      return false;
    }
  };

  // reset = async (showLoader: boolean = false) => {
  //   this.props.setDataHasChanged(false);
  //   await patchAttendanceList(this.state.attendanceList.id, 'anwesenheitslistenstatus', MeetingFormStepStatus.Processed);
  //   const attendanceList = cloneDeep(this.state.attendanceListInitial);
  //   const attendanceListPrev = cloneDeep(this.state.attendanceList);
  //   await this.setState({
  //     attendanceList: attendanceList,
  //     attendanceListPrev: attendanceListPrev,
  //   });
  //   const result = await this.saveDraft(undefined, undefined, showLoader);
  //   return result;
  // };

  getUserLabel = (id: string) => {
    const user = this.props.allUsers.find(u => u.id.toString() === id);
    return user?.isActive ? user.displayName : user?.displayName + " (Inaktiv)";
  }

  getTypeLabel = (type: number, mitglieder) => {
    if (type === 1) {
      const first = mitglieder.roles.find((r) => r.order === 0 && r.role === 1)
      const second = mitglieder.roles.find((r) => r.order === 1 && r.role === 1)
      if (first) {
        return 'Vorsitzender'
      }
      if (second) {
        return 'Stellv. Vorsitzender'
      }
    }
    let label = ''
    switch (type) {
      case 1: label = 'Mitglied'
        break
      case 2: label = 'Ersatzmitglieder'
        break
      case 3: label = 'JAV'
        break
      case 4: label = 'SBV'
        break
    }
    return label;
  }



  validateAndReturnErrorMessage = (i) => {
    const attendanceList = this.state.attendanceList.anwesenheitslisteneinträge[i]
    if (!attendanceList.response) {
      return TooltipAttendanceList
    }
    if (attendanceList.response === 1 && !attendanceList.participationForm) {
      return RequiredFieldErrorMessage
    }
    if (attendanceList.response === 2 && attendanceList.declineReason === null) {
      return AbsenceReasonNeeded
    }
    return ''
  }

  renderUserDropdowns = (userType) => {
    return this.state.attendanceList.anwesenheitslisteneinträge.sort((a, b) => a.roles[0].order - b.roles[0].order
    )
      .map(
        (u, i) => {
          if (userType === 4 && u.roles.length > 1) {
            return
          }
          return u.roles.map((r) => {
            if (r.role === userType) {
              return <div key={i}> <div className="main-style">
                <div className="persona-style">
                  <Persona
                    size="extra-large"
                    className="persona"
                    // avatar={{ color: 'purple' }}
                    name={this.getUserLabel(u.userId)}
                    primaryText={<div className="primary-text">{this.getUserLabel(u.userId)} </div>}
                    secondaryText={<div className="secondary-text" style={{ display: "flex", flexDirection: "column" }}>
                      {u.roles.sort((s1, s2) => s1.role - s2.role).map((r, i) => <span key={i}>{this.getTypeLabel(r.role, u)}</span>)}
                    </div>}>
                  </Persona>
                </div>
                <div className="box-style">
                  <div className="fields-style">
                    <Field
                      style={{ width: '100%' }}
                      className="sitzung-form__narrow-field"
                      validationState={this.state.attendanceList
                        .anwesenheitslisteneinträge[i].response === 0 ? "error" : "none"}
                    >
                      <Dropdown
                        placeholder={TeilnahmeLabel}
                        onOptionSelect={(e, v) =>
                          this.onAttendanceStatusChange(e, v, i)
                        }
                        style={{ minWidth: 190 }}
                        disabled={this.isFormDisabled()}
                        className={
                          this.isFormDisabled()
                            ? "disabledInput"
                            : "anw-combobox"
                        }
                        autoFocus={true}
                        value={
                          !this.state.attendanceList
                            .anwesenheitslisteneinträge[i].response
                            ? ""
                            : EinladungsrückmeldungStatus[
                            this.state.attendanceList
                              .anwesenheitslisteneinträge[i].response!
                            ]
                        }
                      >{Object.keys(EinladungsrückmeldungStatus)
                        .filter((key) => isNaN(Number(key)))
                        .map((key, i) => (i == 0 ? "" : <Option key={key} value={key}>{key}</Option>))}</Dropdown>
                    </Field>
                    <Field style={{ width: '100%' }} validationState={!this.validateTeilnahmeFormat(i) ? "error" : "none"} className="sitzung-form__narrow-field">
                      {u.response != EinladungsrückmeldungStatus.Absage ? (
                        <Dropdown
                          placeholder={TeilnahmeFormLabel}
                          onOptionSelect={(e, v) =>
                            this.onAttendanceFormatChange(e, v, i)
                          }
                          autoFocus={true}
                          disabled={this.isFormDisabled()}
                          className={
                            this.isFormDisabled()
                              ? "disabledInput"
                              : "anw-combobox"
                          }
                          value={
                            !this.state.attendanceList
                              .anwesenheitslisteneinträge[i].participationForm
                              ? ""
                              : Teilnahmeform[
                              this.state.attendanceList
                                .anwesenheitslisteneinträge[i]
                                .participationForm!
                              ]
                          }
                        >{Object.keys(Teilnahmeform)
                          .filter((key) => isNaN(Number(key)))
                          .map((key, i) => (i == 0 ? "" : <Option key={key} value={key}>{key}</Option>))}</Dropdown>
                      ) : (
                        <Dropdown
                          placeholder="Verhinderungsgrund"
                          onOptionSelect={(e, v) =>
                            this.onAbsenceReasonChange(e, v, i)
                          }
                          style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                          autoFocus={true}
                          disabled={this.isFormDisabled()}
                          className={
                            this.isFormDisabled()
                              ? "disabledInput"
                              : "anw-combobox"
                          }
                          value={
                            this.state.attendanceList
                              .anwesenheitslisteneinträge[i]
                              .declineReason === null
                              ? ''
                              : rückmeldungVerhinderungsgrundType.find((r) => r.id === this.state.attendanceList.anwesenheitslisteneinträge[i].declineReason!)!.type

                          }
                        > {rückmeldungVerhinderungsgrundType.map((type, i) => <Option key={type.id} value={type.id.toString()}>{type.type}</Option>)}</Dropdown>
                      )}
                    </Field>
                  </div>
                  <Field className="validation-margin" validationMessage={this.validateAndReturnErrorMessage(i)}></Field>
                  <Field >
                    <Input
                      className="anw-input-notiz"
                      placeholder="Notiz"
                      autoComplete="false"
                      onChange={(e) => this.onNoticeChange(e, i)}
                      disabled={this.isFormDisabled()}
                      value={
                        this.state.attendanceList
                          .anwesenheitslisteneinträge[i].responseComment
                      }
                    />
                  </Field>

                </div>
              </div >
              </div>
            }
          })
        })
  }

  render() {
    return (
      <>
        <div className="attendance-list__wrapper">
          <div className="title-margin">
            <h3 className="sitzung-form__section-title">
              Anwesenheitsliste <CustomTooltip content={"Die Rückmeldungen Ihrer Mitglieder auf die Einladung erscheinen automatisch in der App. Rückmeldungen, die Sie auf anderem Weg erhalten haben, können Sie hier pflegen. Wichtig: So lange noch Rückmeldungen offen sind, ist das Erstellen der Anwesenheitsliste über den Abschließenbutton nicht möglich."} relationship={"label"}/>
            </h3>
          </div>
          {this.state.attendanceList.anwesenheitslisteneinträge.filter(
            (u) => u.roles.some((r) => r.role === 1)
          ).length > 0 && (
              <div className="subtitle-margin">
                <Text className="sitzung-form__section-subtitle" as="h4">
                  Ordentliche Mitglieder
                </Text></div>
            )}
          {this.renderUserDropdowns(1)}
          {this.state.attendanceList.anwesenheitslisteneinträge.filter(
            (u) => u.roles.some((r) => r.role === 2)
          ).length > 0 && (
              <div className="subtitle-margin">
                <Text className="sitzung-form__section-subtitle" as="h4">
                  Eratzmitglieder
                </Text>
              </div>
            )
          }
          {this.renderUserDropdowns(2)}
          {this.state.attendanceList.anwesenheitslisteneinträge.filter(
            (u) => u.roles.some((r) => r.role === 3)
          ).length > 0 && (
              <div className="subtitle-margin">
                <Text className="sitzung-form__section-subtitle" as="h4">
                  JAV
                </Text>
              </div>
            )
          }
          {this.renderUserDropdowns(3)}
          {this.state.attendanceList.anwesenheitslisteneinträge.filter(
            (u) => u.roles.some((r) => r.role === 4 && u.roles.length == 1)
          ).length > 0 && (
              <div className="subtitle-margin">
                <Text className="sitzung-form__section-subtitle" as="h4">
                  SBV
                </Text>
              </div>
            )
          }
          {this.renderUserDropdowns(4)}
        </div>
      </>
    );
  }
}

export default AttendanceList;
