import { Field, Button, Spinner, Tooltip, Input, MessageBar } from "@fluentui/react-components";
import { StatusCodes } from "http-status-codes";
import * as React from "react";
import { getGremiumDefaults, getTagesordnungPunktKonfig, patchGremiumKonfig, updateProtokollKonfig } from "../../api/konfiguration-api";
import Constants, { Navigation, Themes } from "../../constants/constants";
import { ActivityStatus } from "../../models/activity-status";
import IToastNotification from "../../models/toast-notification";
// import StatusBar from "../common/toast-notification/toast-notification";
import TagesordnungInputList from "../common/input-list/tagesordnung-input-list";
import { ConfirmResetToDefault, LoaderMessage, RequiredFieldErrorMessage } from "../../constants/textLabels";
import ITagesordnungspunktVorlage from "../../models/tagesordnung/tagesordnungspunktvorlage";
import { getGremiumDetailDto } from "../../api/konfig-tagesordnungspunktvorlage-api";
import ConfirmDialog from "../common/dialog/confirm-dialog";
import ResetIconButton from "../common/resetIcon/ResetIcon";
import { InfoIcon, ResetIcon } from "../../utils/icons";
import { CustomTooltip } from "../common/CustomTooltip/CustomTooltip";

interface IKonfigurationTagesordnungState {
  isLoading: boolean;
  isMobileView: boolean;
  tagesordnung: ITagesordnungspunktVorlage[];
  notification: IToastNotification;
  activeItem: ITagesordnungspunktVorlage;
  userInputTimeout?;
  formRef;
  configDataChanged: boolean;
  tagesordnungRef;
  tagesordnungName: string;
  gremiumDetailId: string;
  protokollName: string;
  typingTimer: NodeJS.Timeout | undefined;
  gremiumDetails: any;
  confirmDialog;
}

interface IKonfigurationTagesordnungProps {
  userHasUpdatePermission: boolean;
  gremiumId: string;
  navMenuItemClicked: Function;
}

class KonfigurationTagesordnung extends React.Component<
  IKonfigurationTagesordnungProps,
  IKonfigurationTagesordnungState
> {
  theme: string = Themes.default;

  constructor(props: any) {
    super(props);
    this.state = {
      confirmDialog: React.createRef(),
      gremiumDetails: {},
      typingTimer: undefined,
      isLoading: false,
      isMobileView: window.outerWidth <= Constants.maxWidthForMobileView,
      notification: { id: 0, message: "", type: ActivityStatus.None },
      tagesordnung: [],
      activeItem: {
        id: "",
        name: "",
        createdBy: "",
        createdOn: "",
        modifiedBy: "",
        modifiedOn: "",
        sequenz: 0,
        dauer: 0,
        istBeschlussfassungVorgesehen: false,
        istVordefiniert: false,
        vordefinierterTyp: 0,
        hasAttachments: false,
        parentItemId: '',
        responsiblePersonId: '',
        tagesordnungsNummer: 0,
        gremiumId: '',
      },
      formRef: React.createRef(),
      configDataChanged: false,
      tagesordnungRef: React.createRef(),
      tagesordnungName: "",
      gremiumDetailId: "",
      protokollName: ''
    };

    sessionStorage.setItem("configDataChanged", "false");
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.loadTagesordnung();
  }

  componentDidUpdate(prevProps: Readonly<IKonfigurationTagesordnungProps>, prevState: Readonly<IKonfigurationTagesordnungState>, snapshot?: any): void {
    if (prevProps.gremiumId !== this.props.gremiumId) {
      this.loadTagesordnung();
    }
  }

  loadTagesordnung = async () => {
    let response = await getTagesordnungPunktKonfig(this.handleTokenAccessFailure);
    const gremiumId = this.props.gremiumId
    let getGremiumDetail = await getGremiumDetailDto(gremiumId, this.handleTokenAccessFailure)
    let tagesordnungName = getGremiumDetail.data.tagesordnungsname
    if (response.status === StatusCodes.OK && response.data) {
      let tagesordnung: ITagesordnungspunktVorlage[] = response.data;
      tagesordnung.sort((a, b) => {
        return a.sequenz - b.sequenz;
      });
      this.setState({ gremiumDetails: getGremiumDetail.data, tagesordnung: tagesordnung, protokollName: getGremiumDetail.data.protokollname, isLoading: false, tagesordnungName: tagesordnungName, gremiumDetailId: gremiumId })
    }
  };

  handleTokenAccessFailure = (error: string) => {
    alert(error);
  };

  onNameChange = (event: any) => {
    let tagesordnungName = event.target.value;

    this.setState({ tagesordnungName }, () => {
      if (this.state.typingTimer) {
        clearTimeout(this.state.typingTimer);
      }
      this.setState({
        typingTimer: setTimeout(async () => {
          await patchGremiumKonfig(
            this.props.gremiumId,
            "tagesordnungsname",
            tagesordnungName,
            this.handleTokenAccessFailure
          );
        }, 1000),
      });
    });

  };

  onChange = async (event: any) => {
    let name = event.target.value;

    this.setState({ protokollName: name }, () => {
      if (this.state.typingTimer) {
        clearTimeout(this.state.typingTimer);
      }
      this.setState({
        typingTimer: setTimeout(async () => {
          await updateProtokollKonfig(
            "protokollname",
            name,
            this.props.gremiumId,
            this.handleTokenAccessFailure
          );
        }, 1000),
      });
    });

  };

  isFormValid = () => {
    return this.state.protokollName.trim() !== "";
  };

  onTagesordnungChange = (event) => {
    this.state.formRef.current.dispatchEvent(new Event("input"));
  };

  configDataChange = () => {
    this.setState({ configDataChanged: true }, () =>
      sessionStorage.setItem("configDataChanged", "true")
    );
  };

  resetToDefault = async () => {
    this.state.confirmDialog.current.showConfirmDialog(ConfirmResetToDefault, async () => {
      const resp = await getGremiumDefaults(this.handleTokenAccessFailure)
      const defaultData = resp.data
      this.setState({ tagesordnungName: defaultData.tagesordnungsname })
      await patchGremiumKonfig(this.state.gremiumDetailId, "tagesordnungsname", defaultData.tagesordnungsname, this.handleTokenAccessFailure)
    })
  }

  resetToDefaultProtokoll = async () => {
    this.state.confirmDialog.current.showConfirmDialog(ConfirmResetToDefault, async () => {
      const resp = await getGremiumDefaults(this.handleTokenAccessFailure)
      const defaultData = resp.data
      this.setState({ protokollName: defaultData.protokollname })
      await patchGremiumKonfig(this.state.gremiumDetailId, "protokollname", defaultData.protokollname, this.handleTokenAccessFailure)
    })
  }

  render = () => {
    return (
      <div className="container-div">
        {/* <StatusBar notification={this.state.notification} /> */}
        <ConfirmDialog ref={this.state.confirmDialog} />
        {this.state.isLoading ? (
          <Spinner
            style={{
              width: "100%",
              height: "100vh",
              position: "fixed",
              top: "0",
              left: "0",
            }}
            className="konfiguration-spinner"
            label={LoaderMessage}
          />
        ) : (
          <div
            className="Konfiguration-form"
            ref={this.state.formRef}
            onInput={this.configDataChange}
          >
            <h1 className="Konfiguration-formSectionTitle">Tagesordnung und Protokoll <CustomTooltip relationship={"label"} content={"Hier können Sie die bei Ihnen in jeder Sitzung wiederkehrenden Tagesordnungspunkte hinterlegen, die Ihnen dann als Vorlage für jede neue Sitzungen angeboten werden. In der Grundkonfiguration enthält die App bereits typische und/oder rechtlich erforderliche Tagesordnungspunkte (TOP). Über die drei Punkte können Sie TOP's bearbeiten oder löschen, über die Pfeile die Reihenfolge verschieben. Auch die Protokollstruktur wird auf Basis der Tagesordnung automatisch erstellt. Einzelheiten können im automatisch erstellten Word-Dokument hinzugefügt und überarbeitet werden."}/></h1>
            <Field
              validationMessage={this.state.tagesordnungName.trim() === '' ? RequiredFieldErrorMessage : ''}
              validationState={`${this.state.tagesordnungName.trim() === '' ? 'error' : "none"}`}
              label={<>Überschrift der Tagesordnung{" "}
                <CustomTooltip
                  relationship="label"
                  content="Die Werte in den geschweiften Klammern  sind {PLATZHALTER} für Automatisierung. Vorsicht beim Löschen!"/>
                <Button appearance="transparent" shape="circular" style={{ padding: 2, minWidth: 5, marginLeft: 5 }} onClick={this.resetToDefault}><span><ResetIcon /></span></Button></>}>
              <Input
                required
                value={this.state.tagesordnungName}
                onChange={(event, i) => this.onNameChange(event)}
                style={{ width: "100%" }}
                disabled={!this.props.userHasUpdatePermission}
              />
            </Field>
            <Field onInput={this.onTagesordnungChange}>
              <TagesordnungInputList
                dataHasChanged={() => { }}
                orderHasChanged={() => { }}
                isSitzung={null}
                gremiumDetailId={this.props.gremiumId}
                ref={this.state.tagesordnungRef}
                data={this.state.tagesordnung}
                gremiumDetails={this.state.gremiumDetails}
                disabled={!this.props.userHasUpdatePermission}
              />
            </Field>
            <Field validationMessage={this.state.protokollName.trim() === ""
              ? RequiredFieldErrorMessage
              : ""} validationState={this.state.protokollName.trim() === "" ? "error" : "none"} label={<>Protokoll{" "}
                <CustomTooltip
                  relationship="label"
                  content="Hier können Sie die Kopfzeile Ihres Protokolls verändern. Die Werte in den geschweiften Klammern sind {PLATZHALTER} für Automatisierung. Wenn Sie diese löschen, werden Sitzungsart und Sitzungsdatum nicht automatisch übertragen."/>
               <Button appearance="transparent" shape="circular" style={{ padding: 2, minWidth: 5, marginLeft: 5 }} onClick={this.resetToDefaultProtokoll}><span><ResetIcon /></span></Button>
                </>
                }
                >
              <Input
                value={this.state.protokollName}
                onChange={this.onChange}
                style={{ width: "100%" }}
                disabled={!this.props.userHasUpdatePermission}
              />
            </Field>
            <div
              className="konfiguration__save-btn-wrapper"
              hidden={this.state.isLoading}
            >
              <Button appearance="secondary" onClick={() => this.props.navMenuItemClicked(Navigation.Mitglieder)}>Zurück</Button>
              <Button appearance="primary" onClick={() => this.props.navMenuItemClicked(Navigation.Einladung)}>Weiter</Button>
            </div>
          </div>
        )}
      </div>
    );
  };
}

export default KonfigurationTagesordnung;
