import {
  Button,
  Dialog,
  DialogBody,
  DialogSurface,
  DialogTitle,
  DialogContent,
  Option,
  Combobox,
  Input,
  Field,
  makeStyles,
  Popover,
} from "@fluentui/react-components";
import { AddSquare24Filled, DismissFilled, SubtractSquare24Filled } from "@fluentui/react-icons";
import {
  GremiumSameNameErrorMessage,
  RequiredFieldErrorMessage,
  RequiredMitgliederNumberErrorMessage,
} from "../../../constants/textLabels";
import { WarningDialog } from "../../common/WarningDialog/WarningDialog";
import { useEffect, useState } from "react";
import IGraphUser from "../../../models/graph-user";
import {
  customComboboxSearch,
  sortUsers,
} from "../../../utils/combobox-search";
import "./konfiguration-form.scss";
import { mergeArraysWithoutDuplicatesID } from "../../../utils/utility";
import { CustomTooltip } from "../../common/CustomTooltip/CustomTooltip";

const useStyles = makeStyles({
  listbox: {
    // maxHeight will be applied only positioning autoSize set.
    maxHeight: "250px",
  },
  option: {
    height: "50px",
  },
});
const UPARROW = <AddSquare24Filled/>
const DOWNARROW = <SubtractSquare24Filled />

export const KonfigurationGremiumForm = ({
  configDataChange,
  adminDeleteDialogOpen,
  setAdminDeleteDialogOpen,
  currentLoggedUserId,
  initialGremium,
  onGremiennameChange,
  userHasUpdatePermission,
  onFirmenameChange,
  onOrtFilialeChange,
  onMitgliederChange,
  onErsatzmitgliederChange,
  onJAVChange,
  onSBVChange,
  onGremiumMailChange,
  onGremiumAssistentChange,
  adminUsers,
  assistanceUsers,
  selectedAsistents,
  selectedAdministrators,
  adminDropdownRef,
  teams,
  selectedTeam,
  onTeamChange,
  isLoading,
  configDataChanged,
  onDataUpdate,
  isKonfigurationGremium,
  dialogSaveFunction = () => {},
  dialogAbortFunction = () => {},
  updateAdministratorUserNames,
  onGremiumAdministratorChange,
  updatedAdministratorUserNames,
  isFormValid,
  isCreateNewAuschuss,
  navMenuItemClicked = () => {},
  setDeleteAdmin,
  gremiumNameExists,
  updatedAssitentUserNames,
  updateAssistentUserNames,
  isAuschuss,
  loadMoreUsers,
  nextLink,
  searchForUsers,
  searchForUsersNextLink,
  adminAddDialogOpen,
  setAdminAddDialogOpen,
  setAddAdmin,
}) => {
  const formRef = null;
  const [adminOptions, setAdminOptions] = useState<IGraphUser[]>(adminUsers);
  const [assistentsOptions, setAssistentsOptions] =
    useState<IGraphUser[]>(assistanceUsers);
  const [teamsOptions, setTeamsOptions] = useState<IGraphUser[]>(teams)
  const [isLoadingItems, setIsLoadingItems] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchStringAsistentz, setSearchStringAsistentz] = useState("");
  const [searchStringAdmin, setSearchStringAdmin] = useState("");
  const [searchStringTeams, setSearchStringTeams] = useState(selectedTeam.name)
  const [lookForMore, setLookForMore] = useState(nextLink);
  const [searchNextLink, setSearchNextLink] = useState(null);
  const [canChange, setCanChange] = useState(true);
  const styles = useStyles();

  useEffect(()=>{
      setSearchStringTeams(selectedTeam.name)
  },[selectedTeam.name])

  const loaderIntersectionObserverAsistentz = new IntersectionObserver(
    async (entries) => {
      // If intersectionRatio is 0, the target is out of view
      // and we do not need to do anything.
      if (entries[0].intersectionRatio <= 0) return;
      if (isLoadingItems) return;

      setIsLoadingItems(true);
      const users = await loadMoreUsers();
      if (searchStringAsistentz !== "") {
        setIsLoadingItems(false);
        console.log("Stopped loading items because search is in progress");
        return;
      }
      if (users?.assistanceUsers) {
        //Set both because we want the same users in both of the dropdowns
        setAssistentsOptions(users?.assistanceUsers);
        setAdminOptions(users?.adminUsers);
        setLookForMore(users?.nextLink);
      }
      setIsLoadingItems(false);
    }
  );

  const searchIntersectionObserverAsistentz = new IntersectionObserver(
    async (entries) => {
      // If intersectionRatio is 0, the target is out of view
      // and we do not need to do anything.
      if (entries[0].intersectionRatio <= 0) return;

      if (!searchNextLink || isSearching) return;
      setIsSearching(true);
      await new Promise((r) => setTimeout(r, 1500));
      const { users, nextLink } = await searchForUsersNextLink(searchNextLink);
      setSearchNextLink(nextLink);
      if (users.length) {
        const newSortedUsers = sortUsers(users, selectedAsistents);
        setAssistentsOptions((prevState) =>
          mergeArraysWithoutDuplicatesID(prevState, newSortedUsers)
        );
        setIsSearching(false);
      }
    }
  );

  const loaderIntersectionObserverAdmin = new IntersectionObserver(
    async (entries) => {
      // If intersectionRatio is 0, the target is out of view
      // and we do not need to do anything.
      if (entries[0].intersectionRatio <= 0) return;
      if (isLoadingItems) return;

      setIsLoadingItems(true);
      const users = await loadMoreUsers();
      if (searchStringAdmin !== "") {
        setIsLoadingItems(false);
        console.log("Stopped loading items because search is in progress");
        return;
      }
      if (users?.adminUsers) {
        setAdminOptions(users?.adminUsers);
        setAssistentsOptions(users?.assistanceUsers);
        setLookForMore(users?.nextLink);
      }
      setIsLoadingItems(false);
    }
  );

  const searchIntersectionObserverAdmin = new IntersectionObserver(
    async (entries) => {
      // If intersectionRatio is 0, the target is out of view
      // and we do not need to do anything.
      if (entries[0].intersectionRatio <= 0) return;

      if (!searchNextLink || isSearching) return;
      setIsSearching(true);
      await new Promise((r) => setTimeout(r, 1500));
      const { users, nextLink } = await searchForUsersNextLink(searchNextLink);
      setSearchNextLink(nextLink);
      if (users.length) {
        const newSortedUsers = sortUsers(users, selectedAdministrators);
        setAdminOptions((prevState) =>
          mergeArraysWithoutDuplicatesID(prevState, newSortedUsers)
        );
        setIsSearching(false);
      }
    }
  );

  const lastItemRefAsistentz = async (e) => {
    if (e && !isLoadingItems && searchStringAsistentz === "") {
      loaderIntersectionObserverAsistentz.observe(
        document.querySelector(".lastItemInListAsistentz")!
      );
    }
  };

  const searchItemRefAsistentz = async (e) => {
    if (e && searchStringAsistentz !== "") {
      searchIntersectionObserverAsistentz.observe(
        document.querySelector(".searchForMoreAsistentz")!
      );
    }
  };

  const lastItemRefAdmin = async (e) => {
    if (e && !isLoadingItems && searchStringAdmin === "") {
      loaderIntersectionObserverAdmin.observe(
        document.querySelector(".lastItemInListAdmin")!
      );
    }
  };

  const searchItemRefAdmin = async (e) => {
    if (e && searchStringAdmin !== "") {
      searchIntersectionObserverAdmin.observe(
        document.querySelector(".searchForMoreAdmin")!
      );
    }
  };

  useEffect(() => {
    //Timeout so that search goes off after 0.3 seconds from not typing anymore
    const search = setTimeout(async () => {
      setIsSearching(true);
      const { users, nextLink } = await searchForUsers(searchStringAsistentz);
      setSearchNextLink(nextLink);
      if (users.length) {
        setAssistentsOptions((prevState) =>
          sortUsers(
            mergeArraysWithoutDuplicatesID(prevState, users),
            selectedAsistents
          )
        );
      }
      setIsSearching(false);
    }, 300);
    return () => clearTimeout(search);
  }, [searchStringAsistentz]);

  useEffect(() => {
    //Timeout so that search goes off after 0.3 seconds from not typing anymore
    const search = setTimeout(async () => {
      setIsSearching(true);
      const { users, nextLink } = await searchForUsers(searchStringAdmin);
      setSearchNextLink(nextLink);
      if (users.length) {
        setAdminOptions((prevState) =>
          sortUsers(
            mergeArraysWithoutDuplicatesID(prevState, users),
            selectedAdministrators
          )
        );
      }
      setIsSearching(false);
    }, 300);
    return () => clearTimeout(search);
  }, [searchStringAdmin]);

  const AdminAddDialogContent = ({ onSave, onCancel }) => (
       <div className="Konfiguration-admin-delete-modal"> 
          <div>
            Achtung: <br />
            Sind Sie sicher, dass Sie diesen Gremium Administrator hinzufügen
            möchten? <br />
            Er hat danach alle Rechte für dieses Gremium!
          </div>
          <div
          className="Konfiguration-admin-add-modal"
          >
            <Button appearance="secondary" onClick={onSave}>
              Speichern
            </Button>
            <Button appearance="secondary" onClick={onCancel}>
              Abbrechen
            </Button>
          </div>
        </div>
  );

  const AdminAddDialog = ({
    isOpen,
    onClose,
    onSave,
    isKonfigurationGremium,
  }) => {
    const dialogProps = {
      open: isOpen,
      onClose,
    };

    return isKonfigurationGremium ? (
      <Dialog {...dialogProps}>
        <DialogSurface className="admin-adding-dialog">
          <AdminAddDialogContent onSave={onSave} onCancel={onClose} />
        </DialogSurface>
      </Dialog>
    ) : (
      <Popover {...dialogProps}>
        <DialogSurface className="admin-adding-dialog-popover">
          <AdminAddDialogContent onSave={onSave} onCancel={onClose} />
        </DialogSurface>
      </Popover>
    );
  };

  return (
    <div
      className="konfiguration__settings-form"
      style={{ justifyContent: "normal", padding: "5px" }}
      ref={formRef}
      onInput={configDataChange}
    >
      <Dialog open={adminDeleteDialogOpen}>
        <DialogSurface className="admin-adding-dialog">
          <DialogBody style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <DialogTitle style={{ display: "flex", justifyContent: "end" }}>
              <DismissFilled
                className="dialog-close-icon"
                onClick={() => setAdminDeleteDialogOpen(false)}
                y2=""
              ></DismissFilled>
            </DialogTitle>
              <div>
                {updatedAdministratorUserNames?.length === 0 ||
                !updatedAdministratorUserNames?.includes(
                  currentLoggedUserId
                ) ? (
                  <div className="Konfiguration-admin-delete-modal">
                    <div>
                      {updatedAdministratorUserNames?.includes(
                        currentLoggedUserId
                      )
                        ? "Dieses Feld kann nicht leer sein"
                        : "Sie können sich nicht selbst löschen."}
                    </div>
                    <Button
                      appearance="secondary"
                      onClick={() => {
                        setAdminDeleteDialogOpen(false);
                      }}
                    >
                      Schließen
                    </Button>
                  </div>
                ) : (
                  <WarningDialog
                    konfiguration
                    firstButtonFunction={() => {
                      setAdminDeleteDialogOpen(false);
                      setDeleteAdmin(true);
                      updateAdministratorUserNames();
                    }}
                    secondButtonFunction={() => {
                      setAdminDeleteDialogOpen(false);
                      setDeleteAdmin(false);
                    }}
                  />
                )}
              </div>
          </DialogBody>
        </DialogSurface>
      </Dialog>

      <AdminAddDialog
        isOpen={adminAddDialogOpen}
        onClose={() => setAdminAddDialogOpen(false)}
        onSave={() => {
          setAddAdmin(true);
          setAdminAddDialogOpen(false);
        }}
        isKonfigurationGremium={isKonfigurationGremium}
      />
      <h1 className="Konfiguration-formSectionTitle">Gremium <CustomTooltip relationship={"label"} content={"Besonders wichtig ist, dass Sie sorgfältig das Standard-Team auswählen, in dem später alle Sitzungen als Kanäle angelegt werden. Achten Sie hierbei unbedingt darauf, dass nur feste Gremiumsmitglieder zu dem Team hinzugefügt sind."}/></h1>
      <Field
        className="field-style"
        validationMessage={
          isKonfigurationGremium && initialGremium.gremienname === ""
            ? RequiredFieldErrorMessage
            : gremiumNameExists
            ? GremiumSameNameErrorMessage
            : ""
        }
        validationState={
          isKonfigurationGremium && initialGremium.gremienname === ""
            ? "error"
            : gremiumNameExists
            ? "error"
            : "none"
        }
        label="Name"
      >
        <Input
          value={initialGremium.gremienname}
          onChange={onGremiennameChange}
          disabled={!userHasUpdatePermission}
        />
      </Field>
      <></>
      <div className="Konfiguration-formRow">
        <Field
          className="field-style"
          validationMessage={
            isKonfigurationGremium && initialGremium.firmenname === ""
              ? RequiredFieldErrorMessage
              : ""
          }
          validationState={
            isKonfigurationGremium &&
            initialGremium.firmenname === "" &&
            "error"
          }
          label="Firma"
        >
          <Input
            value={initialGremium.firmenname}
            onChange={onFirmenameChange}
            disabled={!userHasUpdatePermission}
          />
        </Field>
        <Field className="field-style" label="Ort/Niederlassung/Betrieb">
          <Input
            value={initialGremium.ortFiliale}
            onChange={onOrtFilialeChange}
            disabled={!userHasUpdatePermission}
          />
        </Field>
      </div>
      <div className="Konfiguration-formRow">
        <Field
          className="field-style"
          validationMessage={
            initialGremium.anzahlMitglieder === 0
              ? RequiredMitgliederNumberErrorMessage
              : ""
          }
          validationState={
            isKonfigurationGremium &&
            initialGremium.anzahlMitglieder === 0 &&
            "error"
          }
          label={
            <>
              Mitglieder{" "}
              <CustomTooltip relationship={"label"} content={"Anzahl der ordentlichen Mitglieder"}/>
            </>
          }
        >
          <div className="number-input-flex">
          <Input
            type="number"
            className="number-input"
            value={initialGremium.anzahlMitglieder}
            disabled={!userHasUpdatePermission}
          />
          <div className="number-input-arrow-up" onClick={() => {  onMitgliederChange(initialGremium.anzahlMitglieder + 1)}}>{UPARROW}</div>
          <div className="number-input-arrow-down" onClick={() => { initialGremium.anzahlMitglieder > 0 && onMitgliederChange(initialGremium.anzahlMitglieder - 1)}}>{DOWNARROW}</div>
          </div>
        </Field>
        <Field
          className="field-style"
          label={
            <>
              Ersatzmitglieder{" "}
              <CustomTooltip relationship={"label"} content={"Anzahl aller Ersatzmitglieder (bei Verhältniswahl die aller Listen), wenn vorhanden"}/>
            </>
          }
        >
          <div className="number-input-flex">

          <Input
            type="number"
            className="number-input"
            value={initialGremium.anzahlErsatzmitglieder.toString()}
            disabled={!userHasUpdatePermission}
            />
            <div className="number-input-arrow-up" onClick={() => {  onErsatzmitgliederChange(initialGremium.anzahlErsatzmitglieder + 1)}}>{UPARROW}</div>
            <div className="number-input-arrow-down" onClick={() => { initialGremium.anzahlErsatzmitglieder > 0 && onErsatzmitgliederChange(initialGremium.anzahlErsatzmitglieder - 1)}}>{DOWNARROW}</div>
            </div>
        </Field>
      </div>
      <div className="Konfiguration-formRow">
        <Field
          className="field-style"
          label={
            <>
              Jugend und Auszubildendenvertretung{" "}
              <CustomTooltip relationship={"label"} content={"Größe der JAV, Gesamt-JAV oder Konzern-JAV (ohne Ersatzmitglieder), wenn vorhanden"}/>
            </>
          }
        >
          <div className="number-input-flex">
          <Input
            type="number"
            className="number-input"
            value={initialGremium.anzahlJAV.toString()}
            disabled={!userHasUpdatePermission}
            />
          <div className="number-input-arrow-up" onClick={() => {  onJAVChange(initialGremium.anzahlJAV + 1)}}>{UPARROW}</div>
          <div className="number-input-arrow-down" onClick={() => { initialGremium.anzahlJAV > 0 && onJAVChange(initialGremium.anzahlJAV - 1)}}>{DOWNARROW}</div>
            </div>
        </Field>
        <Field
          className="field-style"
          label={
            <>
              {" "}
              Schwerbehindertenvertretung (SBV){" "}
              <CustomTooltip relationship={"label"} content={"Vertrauensperson (SBV), Gesamt-SBV oder Konzern-SBV, plus Stellvertreter, wenn vorhanden"}/>
            </>
          }
        >
          <div className="number-input-flex">
          <Input
            type="number"
            className="number-input"
            value={initialGremium.anzahlSBV.toString()}
            disabled={!userHasUpdatePermission}
            />
            <div className="number-input-arrow-up" onClick={() => {  onSBVChange(initialGremium.anzahlSBV + 1)}}>{UPARROW}</div>
            <div className="number-input-arrow-down" onClick={() => { initialGremium.anzahlSBV > 0 && onSBVChange(initialGremium.anzahlSBV - 1)}}>{DOWNARROW}</div>
            </div>
        </Field>
      </div>
      <div className="Konfiguration-formRow">
        <Field
          className="field-style"
          validationMessage={
            isKonfigurationGremium && initialGremium.brMail === ""
              ? RequiredFieldErrorMessage
              : ""
          }
          validationState={
            isKonfigurationGremium && initialGremium.brMail === "" && "error"
          }
          label={
            <>
              E-Mail des Gremiums{" "}
              <CustomTooltip relationship={"label"} content={"Standard-E-Mail-Adresse des Gremiums in Outlook, über die der Betriebsrat oder der Ausschuss für die Arbeitnehmer erreichbar ist"}/>
            </>
          }
        >
          <Input
            type="email"
            value={initialGremium.brMail}
            onChange={onGremiumMailChange}
            disabled={!userHasUpdatePermission}
          />
        </Field>
        <Field
          className="field-style"
          validationMessage={
            (selectedTeam.name === null ||
              selectedTeam.name === undefined ||
              selectedTeam.name === "") &&
            isKonfigurationGremium &&
            "Standard Team darf nicht leer sein."
          }
          validationState={
            isKonfigurationGremium &&
            (selectedTeam.name === null ||
              selectedTeam.name === undefined ||
              selectedTeam.name === "") &&
            "error"
          }
          label={
            <div onClick={(e)=>e.preventDefault()}>
              Standard Team{" "}
              <CustomTooltip relationship={"label"} content={"Achtung: Hier wählen Sie die von Ihnen bzw. Ihrer IT bei der Installation definierte Gruppe (Team) aus. In diesem Team werden die Kanäle für die Sitzungen mit dem Zugriff auf die dazugehörigen Dateien erstellt. Achten Sie also immer darauf, dass nur berechtigte Personen Team-Mitglieder sind."}/>
            </div>
          }
        >
          <Combobox
            multiselect={true}
            value={searchStringTeams}
            selectedOptions={[selectedTeam.id]}
            onBlur={() => setSearchStringTeams(selectedTeam.name)}
            onFocus={()=>{
              setSearchStringTeams("")
              setTeamsOptions(teams)
            }}
            onOptionSelect={async (e, v) => {
              if (canChange && v.optionValue !== selectedTeam.id) {
                setCanChange(false);
                await onTeamChange(e,v)
                setCanChange(true);
              }
            }}
            freeform
            onChange={(e) => {
              const filteredTeamsOptions = customComboboxSearch(
                teams,
                e.target.value
              );
              setTeamsOptions(filteredTeamsOptions);
              setIsSearching(true);
              setSearchStringTeams(e.target.value);
            }}
            className={!userHasUpdatePermission ? "disabledInput" : "konfiguration-dropdown"}
            disabled={!userHasUpdatePermission}
            listbox={{ className: styles.listbox }}
            placeholder="Suche nach Team"
          >
            {teamsOptions.map((team, index) => {
                return (
                  <Option
                    key={team.id.toString()}
                    value={team?.id?.toString() || ""}
                    aria-posinset={index}
                    aria-setsize={teamsOptions.length}
                  >
                    {team.displayName}
                  </Option>
                );
              })
            }
            </Combobox>
        </Field>
      </div>
      <div className="Konfiguration-formRow">
        <Field
          className="field-style"
          label={
            <div onClick={(e)=>e.preventDefault()}>
              Assistenz{" "}
              <CustomTooltip relationship={"label"} content={"Vor- und Nachname der Assistenz, wenn vorhanden, Achtung: Sie erhält die gleichen Rechte wie BRV und Stellvertreter"}/>
            </div>
          }
        >
          <Combobox
            multiselect={true}
            selectedOptions={selectedAsistents}
            onOptionSelect={async (e, v) => {
              if (canChange) {
                const userValue = {
                  name: v.optionText,
                  id: v.optionValue,
                  selectedOptions: v.selectedOptions,
                  newUsers: assistanceUsers,
                };
                setCanChange(false);
                await onGremiumAssistentChange(e, userValue);
                setCanChange(true);
                setSearchStringAsistentz("");
              }
            }}
            freeform
            onChange={(e) => {
              const filteredAssistentsOptions = customComboboxSearch(
                assistanceUsers,
                e.target.value
              );
              setAssistentsOptions(
                sortUsers(filteredAssistentsOptions, selectedAsistents)
              );
              setIsSearching(true);
              setSearchStringAsistentz(e.target.value);
            }}
            className={!userHasUpdatePermission ? "disabledInput" : "konfiguration-dropdown"}
            disabled={!userHasUpdatePermission}
            listbox={{ className: styles.listbox }}
            placeholder="Suche nach Assistenz"
          >
            {sortUsers(assistentsOptions, selectedAsistents).map(
              (user, index) => {
                // Handle the last item differently
                if (index === assistentsOptions.length - 1) {
                  return (
                    <>
                      <Option
                        key={user.id.toString()}
                        value={user?.id?.toString() || ""}
                        aria-posinset={index}
                        aria-setsize={assistentsOptions.length}
                      >
                        {`${user.displayName} ${
                          !user.isActive ? "(Inaktiv)" : ""
                        }`}
                      </Option>
                      {searchStringAsistentz === "" &&
                        lookForMore &&
                        !isLoadingItems && (
                          <Option
                            ref={lastItemRefAsistentz}
                            className={"lastItemInListAsistentz"}
                            aria-posinset={index}
                            aria-setsize={assistentsOptions.length}
                            checkIcon={null}
                            disabled
                            value={"Loading more items"}
                            key={`loading-${index}`}
                          >
                            Loading more items...
                          </Option>
                        )}
                      {searchStringAsistentz !== "" &&
                        (searchNextLink || isSearching) && (
                          <Option
                            ref={searchItemRefAsistentz}
                            className={"searchForMoreAsistentz"}
                            aria-posinset={index}
                            aria-setsize={assistentsOptions.length}
                            checkIcon={null}
                            disabled
                            value={"Searching for users..."}
                            key={`searching-${index}`}
                          >
                            Searching for more users...
                          </Option>
                        )}
                    </>
                  );
                } else {
                  return (
                    <Option
                      key={user.id.toString()}
                      value={user?.id?.toString() || ""}
                      aria-posinset={index}
                      aria-setsize={assistentsOptions.length}
                    >
                      {`${user.displayName} ${
                        !user.isActive ? "(Inaktiv)" : ""
                      }`}
                    </Option>
                  );
                }
              }
            )}
          </Combobox>
          <div style={{ marginTop: 5 }}>
            {selectedAsistents.length && assistanceUsers.length
              ? selectedAsistents.map((option, i) => (
                  <Button
                    size="small"
                    shape="circular"
                    appearance="secondary"
                    style={{ margin: 2 }}
                  >
                    {assistanceUsers.find((a) => a?.id === option)
                      ?.displayName || ""}
                  </Button>
                ))
              : null}
          </div>
        </Field>
        <Field
          className="field-style"
          validationMessage={
            isKonfigurationGremium &&
            !selectedAdministrators?.length &&
            "In der Konfiguration ist keine weitere Person hinterlegt."
          }
          validationState={
            isKonfigurationGremium && !selectedAdministrators?.length && "error"
          }
          label={
            <div onClick={(e)=>e.preventDefault()}>
              Gremium Administrator
              <CustomTooltip relationship={"label"} content={"Vor- und Nachname des Administrators mit allen Schreibrechten, mindestens zwei empfohlen"}/>
            </div>
          }
        >
          <Combobox
            multiselect={true}
            selectedOptions={selectedAdministrators}
            onOptionSelect={async (e, v) => {
              if (canChange) {
                const userValue = {
                  name: v.optionText,
                  id: v.optionValue,
                  selectedOptions: v.selectedOptions,
                  newUsers: adminUsers,
                };
                setCanChange(false);
                await onGremiumAdministratorChange(e, userValue);
                setCanChange(true);
                setSearchStringAdmin("");
              }
            }}
            ref={adminDropdownRef}
            freeform
            onChange={(e) => {
              const filteredAdminOptions = customComboboxSearch(
                adminUsers,
                e.target.value
              );
              setAdminOptions(
                sortUsers(filteredAdminOptions, selectedAdministrators)
              );
              setIsSearching(true);
              setSearchStringAdmin(e.target.value);
            }}
            className={!userHasUpdatePermission ? "disabledInput" : "konfiguration-dropdown"}
            disabled={!userHasUpdatePermission}
            listbox={{ className: styles.listbox }}
            placeholder="Suche nach Administrator"
          >
            {sortUsers(adminOptions, selectedAdministrators).map(
              (user, index) => {
                // Handle the last item differently
                if (index === adminOptions.length - 1) {
                  return (
                    <>
                      <Option
                        key={user.id.toString()}
                        value={user?.id?.toString() || ""}
                        aria-posinset={index}
                        aria-setsize={adminOptions.length}
                      >
                        {`${user.displayName} ${
                          !user.isActive ? "(Inaktiv)" : ""
                        }`}
                      </Option>
                      {searchStringAdmin === "" &&
                        lookForMore &&
                        !isLoadingItems && (
                          <Option
                            ref={lastItemRefAdmin}
                            className={"lastItemInListAdmin"}
                            aria-posinset={index}
                            aria-setsize={adminOptions.length}
                            checkIcon={null}
                            disabled
                            value={"Loading more items"}
                            key={`loading-${index}`}
                          >
                            Loading more items...
                          </Option>
                        )}
                      {searchStringAdmin !== "" &&
                        (searchNextLink || isSearching) && (
                          <Option
                            ref={searchItemRefAdmin}
                            className={"searchForMoreAdmin"}
                            aria-posinset={index}
                            aria-setsize={adminOptions.length}
                            checkIcon={null}
                            disabled
                            value={"Searching for users..."}
                            key={`searching-${index}`}
                          >
                            Searching for more users...
                          </Option>
                        )}
                    </>
                  );
                } else {
                  return (
                    <Option
                      key={user.id.toString()}
                      value={user?.id?.toString() || ""}
                    >{`${user.displayName} ${
                      !user.isActive ? "(Inaktiv)" : ""
                    }`}</Option>
                  );
                }
              }
            )}
          </Combobox>
          <div style={{ marginTop: 5 }}>
            {selectedAdministrators.length && adminUsers.length
              ? selectedAdministrators.map((option, i) => (
                  <Button
                    size="small"
                    shape="circular"
                    appearance="secondary"
                    style={{ margin: 2 }}
                  >
                    {adminUsers.find((a) => a?.id === option)?.displayName ||
                      ""}
                  </Button>
                ))
              : null}
          </div>
        </Field>
      </div>
      {isKonfigurationGremium && !isCreateNewAuschuss ? (
        <div className="konfiguration__save-btn-wrapper" hidden={isLoading}>
          <Button appearance="secondary" disabled>
            Zurück
          </Button>
          <Button appearance="primary" onClick={() => navMenuItemClicked()}>
            Weiter
          </Button>
        </div>
      ) : (
        <div className="konfiguration__save-btn-wrapper" hidden={isLoading}>
          <Button
            appearance="primary"
            onClick={dialogSaveFunction}
            disabled={!configDataChanged}
          >
            Speichern
          </Button>
          <Button appearance="secondary" onClick={dialogAbortFunction}>
            Abbrechen
          </Button>
        </div>
      )}
    </div>
  );
};
