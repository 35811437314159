import { Button, Spinner, Textarea, Table, TableHeader, TableBody, TableRow, TableCellLayout, TableHeaderCell, TableCell, MessageBar, Divider, } from "@fluentui/react-components";
import { StatusCodes } from "http-status-codes";
import * as React from "react";
import { ConfirmResetToDefault, LoaderMessage } from "../../constants/textLabels";
import Constants, { Navigation, Themes } from "../../constants/constants";
import { ActivityStatus } from "../../models/activity-status";
import IToastNotification from "../../models/toast-notification";
// import StatusBar from "../common/toast-notification/toast-notification";
import { deleteChecklistTemplateItem, getDefaultChecklistValues, getGremiumCheckliste, patchChecklistTemplateItem, postChecklistTemplateItem } from "../../api/checklist-dto-template-api";
import ICheckliste from "../../models/checkliste/checkliste";
import { Guid } from "guid-typescript";
import ICreateCheckliste from "../../models/checkliste/create-checkliste";
import ConfirmDialog from "../common/dialog/confirm-dialog";
import { AddIcon, ArrowDownIcon, ArrowUpIcon, CheckIcon, CloseIcon, InfoIcon, MoreHorizontalIcon, PencilIcon, TrashIcon } from "../../utils/icons";
import ResetToDefaultButton from "../common/ResetToDefaultButton/ResetToDefaultButton";
import { CustomTooltip } from "../common/CustomTooltip/CustomTooltip";

interface IKonfigurationChecklisteState {
  isLoading: boolean;
  isMobileView: boolean;
  checkliste: ICheckliste[];
  createChecklist: ICreateCheckliste;
  notification: IToastNotification;
  userInputTimeout?;
  configDataChanged: boolean;
  sortedCheckliste: ICheckliste[];
  newChecklistText: string;
  addNew: boolean;
  confirmDialogRef;
  isEdit: number;
  checklisteEditText: string;
  defaultChecklists: ICheckliste[];
  actionListRef;
}

interface IKonfigurationChecklisteProps {
  userHasUpdatePermission: boolean;
  navMenuItemClicked: Function;
}

class KonfigurationCheckliste extends React.Component<
  IKonfigurationChecklisteProps,
  IKonfigurationChecklisteState
> {
  theme: string = Themes.default;

  constructor(props: any) {
    super(props);
    this.state = {
      actionListRef: React.createRef(),
      defaultChecklists: [{
        id: Guid.createEmpty(),
        councilId: Guid.createEmpty(),
        name: "",
        order: 0
      }],
      checklisteEditText: '',
      isEdit: -1,
      confirmDialogRef: React.createRef(),
      isLoading: false,
      isMobileView: window.outerWidth <= Constants.maxWidthForMobileView,
      checkliste: [{
        id: Guid.createEmpty(),
        councilId: Guid.createEmpty(),
        name: "",
        order: 0
      }],
      createChecklist: {
        id: Guid.createEmpty(),
        createdOn: new Date(),
        createdBy: Guid.createEmpty(),
        modifiedOn: new Date(),
        modifiedBy: Guid.createEmpty(),
        councilId: Guid.createEmpty(),
        name: '',
        order: 0
      },
      sortedCheckliste: [],
      notification: { id: 0, message: "", type: ActivityStatus.None },
      configDataChanged: false,
      newChecklistText: '',
      addNew: false
    };
    sessionStorage.setItem("configDataChanged", "false");
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.setState({ isLoading: true });
    this.loadCheckliste();
  }

  handleClickOutside = (e) => {
    if (this.state.actionListRef.current && !this.state.actionListRef.current.contains(e.target)) {
      const isActionListItem = e.target.closest('.action-list-item');

      if (!isActionListItem) {
        this.closeAllActionLists();
      }
    }
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  moveUp = async (checklist: ICheckliste) => {
    if (checklist.order === 1) { return }
    const prev = this.state.checkliste.find((c) => c.order === (checklist.order - 1))
    await patchChecklistTemplateItem(checklist.id.toString(), "order", prev?.order!)
    await patchChecklistTemplateItem(prev!.id.toString(), "order", checklist?.order!)
    this.loadCheckliste()
  }


  moveDown = async (checklist: ICheckliste) => {
    if (checklist.order === this.state.checkliste.length) { return }
    const current = this.state.checkliste
    const prev = current.find((c) => c.order === (checklist.order + 1))
    await patchChecklistTemplateItem(checklist.id.toString(), "order", prev?.order!)
    await patchChecklistTemplateItem(prev!.id.toString(), "order", checklist?.order!)
    this.loadCheckliste()
  }

  loadCheckliste = async () => {
    const gremiumId = localStorage.getItem("selectedAuschuss")!
    const response = await getGremiumCheckliste(gremiumId, this.handleTokenAccessFailure)
    const checklistData: ICheckliste[] = response.data
    const filter: ICheckliste[] = checklistData.filter((c) => c.councilId === gremiumId)

    if (response.status === StatusCodes.OK && response.data) {
      this.setState({ checkliste: filter }, this.sortCheckliste);
    }
  };

  sortCheckliste = () => {
    const checkliste = this.state.checkliste
    const sorted: ICheckliste[] = checkliste.sort((a, b) => a.order - b.order)
    let sortedCheckliste: ICheckliste[] = []
    sorted.forEach((c) => sortedCheckliste.push(c))
    sortedCheckliste.forEach((c, i) => this.renderChecklists(i, c))
    this.setState({ sortedCheckliste: sortedCheckliste, isLoading: false })
  }

  handleTokenAccessFailure = (error: string) => {
    alert(error);
  };

  configDataChange = () => {
    this.setState({ configDataChanged: true }, () =>
      sessionStorage.setItem("configDataChanged", "true")
    );
  };

  onChangeText = async (e, checklist: ICheckliste) => {
    this.setState({ checklisteEditText: e.target.value })

  }

  addNewChecklist = () => {
    let index = 1
    if (this.state.checkliste.length > 0) {
      index = this.state.checkliste.length + 1

    }
    return <TableRow key={index} style={{ border: "none" }}>
      <TableCell>
        <TableCellLayout>
          <div style={{ display: "flex", alignItems: "center", marginLeft: 5 }}>
            {index}
          </div></TableCellLayout>
      </TableCell>
      <TableCell style={{ padding: "5px 0" }}>
        <Textarea onChange={(e) => this.setState({ newChecklistText: e.target.value })} style={{ width: "100%" }}>{this.state.newChecklistText}</Textarea>
      </TableCell>
      <TableCell>
        <div
          className='editFinalizationBtnGroup'>
          <Button appearance='transparent' icon={<span className="check-icon-color"><CheckIcon /></span>} title='speichern' key={'check'} onClick={e => { this.addChecklist(index) }}></Button>
          <Button appearance='transparent' icon={<span className="close-icon-color"><CloseIcon /></span>} title='abbrechen' key={'close'} onClick={e => this.setState({ isEdit: -1, addNew: false })}></Button>
        </div>
      </TableCell>
    </TableRow >
  }

  addChecklist = async (index) => {
    const item = {
      councilId: localStorage.getItem("selectedAuschuss"),
      name: this.state.newChecklistText,
      order: index
    }
    await postChecklistTemplateItem(item, this.handleTokenAccessFailure)
    this.setState({ addNew: false, newChecklistText: '' })
    this.loadCheckliste()
  }

  deleteChecklistItem = async (checklist: ICheckliste) => {
    this.state.confirmDialogRef.current.showConfirmDialog(
      "Sind Sie sicher, dass Sie diesen Punkt löschen möchten?",
      async () => {
        this.setState({ isLoading: true })
        await deleteChecklistTemplateItem(checklist.id.toString())
        this.setState({ newChecklistText: '' })

        const patchPromises = this.state.sortedCheckliste.map(async (c, i) => {
          if (c.order > checklist.order) {
            await patchChecklistTemplateItem(c.id.toString(), 'order', i, this.handleTokenAccessFailure)
          }
        })
        await Promise.all(patchPromises);
        this.closeAllActionLists()
        this.loadCheckliste()
      }
    );

  }


  actionsCell = (rowIndex, row) => {
    if (rowIndex !== this.state.isEdit || rowIndex === -1) {
      return <> <Button
        ref={this.state.actionListRef}
        className="loadActionList"
        icon={<span style={{ fontSize: "20px" }}><MoreHorizontalIcon /></span>}
        appearance='transparent'
        title="Weitere Aktionen"
        disabled={!this.props.userHasUpdatePermission || this.state.isEdit !== -1}
        onClick={(e) => { this.toggleActionList(e) }}
      />
        <ul className='action-list' hidden>
          <li className='action-list-item' onClick={e => this.handleBearbeitenClick(e, rowIndex, row)}>
            <span style={{ fontSize: "20px" }}><PencilIcon /></span>
            Bearbeiten
          </li>
          <li className='action-list-item' onClick={e => this.deleteChecklistItem(row)}>
            <span style={{ fontSize: "20px" }}><TrashIcon /></span>
            Entfernen
          </li>
        </ul>
      </>
    }
    else {
      return <>
        <div
          className='editFinalizationBtnGroup'>
          <Button appearance='transparent' icon={<span className="check-icon-color"><CheckIcon /></span>} title='speichern' key={'check'} onClick={e => { this.handleCheckClick(row) }}></Button>
          <Button appearance='transparent' icon={<span className="close-icon-color"><CloseIcon /></span>} title='abbrechen' key={'close'} onClick={e => this.handleCloseClick(e)}></Button>
        </div>
      </>
    }
  }

  handleCheckClick = async (checklist) => {
    await patchChecklistTemplateItem(checklist.id, "name", this.state.checklisteEditText)
    this.setState({ isEdit: -1 })
    this.loadCheckliste()
  }


  handleCloseClick = (e) => {
    this.setState({
      isEdit: -1,
    });
  }

  handleBearbeitenClick = (e, rowIndex, row) => {
    this.setState({ isEdit: rowIndex, checklisteEditText: row.name })
  }

  toggleActionList = (e) => {
    const isCurrentlyDisplayed = !e.currentTarget.nextElementSibling.hasAttribute('hidden');
    this.closeAllActionLists();
    if (isCurrentlyDisplayed) {
      e.currentTarget.nextElementSibling.setAttribute('hidden', '');
    } else {
      e.currentTarget.nextElementSibling.removeAttribute('hidden');
      e.currentTarget.nextElementSibling.scrollIntoView({ block: "end", inline: "nearest", behavior: 'smooth' });
    }
  }

  closeAllActionLists = () => {
    const actionLists = [].slice.call(document.getElementsByClassName('action-list'));
    if (actionLists) {
      actionLists.forEach(actionList => {
        let actionListEl: HTMLElement = actionList;
        actionListEl.hidden = true;
      });
    }
  }

  renderChecklists = (i, checklist: ICheckliste) => {
    return <TableRow key={checklist.order} style={{ border: "none" }}>
      <TableCell>
        <TableCellLayout>
          <div className="checklist-number-order">
            <div style={{ display: "flex", alignItems: "center", marginLeft: 5 }}>{checklist.order}</div>
            <div className="checklist-buttons-order" style={{ display: "flex", alignItems: "center", marginLeft: 5}}>
              <Button disabled={!this.props.userHasUpdatePermission || this.state.isEdit !== -1} appearance='transparent' icon={<span><ArrowUpIcon /></span>} onClick={e => this.moveUp(checklist)} />
              <Button disabled={!this.props.userHasUpdatePermission || this.state.isEdit !== -1} appearance='transparent' icon={<span><ArrowDownIcon /></span>} onClick={e => this.moveDown(checklist)} />
            </div>
          </div></TableCellLayout>
      </TableCell>
      <TableCell style={{ padding: "5px 0", width: '100px' }}>
        <div style={{ display: "flex" }}>
          {this.state.isEdit === i ? <Textarea onChange={(e) => this.onChangeText(e, checklist)} style={{ width: "100%" }}>{checklist.name}</Textarea> : checklist.name}
        </div>
      </TableCell>
      <TableCell>
       <div style={{display: 'flex', justifyContent: 'center'}}> {this.actionsCell(i, checklist)}</div>
      </TableCell>
    </TableRow>
  }

  resetToDefault = async () => {
    const resp = await getDefaultChecklistValues(this.handleTokenAccessFailure)
    this.setState({ defaultChecklists: resp.data, checklisteEditText: '' }, this.handleReset)
  }

  handleReset = async () => {
    this.state.confirmDialogRef.current.showConfirmDialog(ConfirmResetToDefault, async () => {
      this.setState({ isLoading: true })
      const { checkliste, defaultChecklists } = this.state

      const deletePromises = checkliste.map(async (c, i) => {
        await deleteChecklistTemplateItem(c.id.toString(), this.handleTokenAccessFailure)
      });
      await Promise.all(deletePromises);

      const postPromises = defaultChecklists.map(async (c, i) => {
        const checklist: ICheckliste = {
          id: Guid.createEmpty().toString(),
          councilId: localStorage.getItem("selectedAuschuss")!,
          name: c.name,
          order: i + 1
        }
        await postChecklistTemplateItem(checklist, this.handleTokenAccessFailure)
      })
      await Promise.all(postPromises);
      this.loadCheckliste()
    })
  }

  render = () => {
    return (
      <div className="container-div">
        <div className="Konfiguration-checklist">
          <ConfirmDialog ref={this.state.confirmDialogRef} />
          {/* <StatusBar notification={this.state.notification} /> */}
          <h1 className="Konfiguration-formSectionTitle">Checkliste <CustomTooltip relationship={"label"} content={"Hier können Sie eine Checkliste für die Schritte erstellen, die bei Ihnen im Gremium standardmäßig nach jeder Sitzung passieren sollen (z.B.: Protokoll unterschreiben und ablegen, Beschlüsse versenden, usw.). So denken Sie an alles Wichtige."}/></h1>
          {this.state.isLoading ? (
            <Spinner
              style={{
                width: "100%",
                height: "100vh",
                position: "fixed",
                top: "0",
                left: "0",
              }}
              className="konfiguration-spinner"
              label={LoaderMessage}
            />
          ) : (<>
            <div className="konfiguration__save-btn-wrapper" style={{ marginBottom: 10 }}>
              <Button
                disabled={!this.props.userHasUpdatePermission}
                icon={<span style={{ fontSize: "20px" }}><AddIcon /></span>}
                appearance="secondary" onClick={() => this.setState({ addNew: true })}>Hinzufügen</Button>
              <ResetToDefaultButton disabledState={null} onClick={this.resetToDefault} />
            </div>
            <Divider />
            <div className="table-container">
            <Table className='table-container-overflow' style={{overflow: 'scroll'}}  sortable>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell style={{ width: 50, fontWeight: "bold" }}>Nr.</TableHeaderCell>
                  <TableHeaderCell style={{ fontWeight: "bold", width: 200 }}>Name</TableHeaderCell>
                  <TableHeaderCell style={{ width: 30 }}></TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {this.state.sortedCheckliste.length !== 0 ? this.state.sortedCheckliste.map((c, index) => <>
                  {this.renderChecklists(index, c)}
                  {this.state.addNew && this.state.sortedCheckliste.length - 1 === index && this.addNewChecklist()}
                </>) : !this.state.addNew ? <h4 style={{ textAlign: "center", margin: "10px 0", width: 400 }}>
                  In dieser Konfiguration gibt es keine Checkliste
                </h4>
                  : this.state.addNew && this.addNewChecklist()}
              </TableBody>
            </Table>
            </div>
            <div
              className="konfiguration__save-btn-wrapper"
              hidden={this.state.isLoading}
            >
              <Button appearance="secondary" onClick={() => this.props.navMenuItemClicked(Navigation.Anwesenheit)}>Zurück</Button>
            </div>
          </>
          )}
        </div>
      </div>
    );
  };
}

export default KonfigurationCheckliste;
